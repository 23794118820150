import React, { useState, useEffect, useRef } from 'react';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, CartesianGrid } from 'recharts';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { X, Info } from 'lucide-react';
import { Avatar, AvatarImage, AvatarFallback } from "../components/ui/avatar";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import axios from 'axios';
import { useToast } from "../components/ui/use-toast";

const BOTTOM_MENU_HEIGHT = '4rem';

const gameContainerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: BOTTOM_MENU_HEIGHT,
    backgroundColor: 'black',
    zIndex: 50,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
};

const HOUSE_EDGE = 0.03;
const CRASH_CHANCE = 0.02;
const GROWTH_CHANCE = 0.7;
const MAX_MULTIPLIER = 10;

const generateData = (length) => {
    let data = [];
    let value = 1;
    for (let i = 0; i < length; i++) {
        if (Math.random() < CRASH_CHANCE) {
            value = 1;
        } else if (Math.random() < GROWTH_CHANCE) {
            value = Math.min(value * (1 + (Math.random() * 0.2 - HOUSE_EDGE)), MAX_MULTIPLIER);
        } else {
            value = Math.max(value * (1 - Math.random() * 0.1), 1);
        }
        data.push({ time: i, value: value });
    }
    return data;
};

const BrandGrowthGame = ({ user, setUser, setGlobalUser, onClose, updateUserData }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentMultiplier, setCurrentMultiplier] = useState(1);
    const [data, setData] = useState(generateData(1000));
    const [visibleData, setVisibleData] = useState([]);
    const [bet, setBet] = useState(100);
    const [result, setResult] = useState(null);
    const [winAmount, setWinAmount] = useState(0);
    const [cashedOut, setCashedOut] = useState(false);
    const [crashed, setCrashed] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(user.coins);
    const [gameEnded, setGameEnded] = useState(false);
    const timerRef = useRef(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [isBrandInfoOpen, setIsBrandInfoOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [animatingBalance, setAnimatingBalance] = useState(currentBalance);
    const [isAnimating, setIsAnimating] = useState(false);
    const { toast } = useToast();

    const [brands, setBrands] = useState([]);

    const animateBalance = (start, end, duration) => {
        setIsAnimating(true);
        const startTime = Date.now();
        const animate = () => {
            const now = Date.now();
            const progress = Math.min((now - startTime) / duration, 1);
            const current = Math.floor(start + progress * (end - start));
            setAnimatingBalance(current);
            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                setIsAnimating(false);
            }
        };
        requestAnimationFrame(animate);
    };

    useEffect(() => {
        setCurrentBalance(user.coins);
    }, [user.coins]);

    useEffect(() => {
        const fetchBrands = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get('/api/brands');
                console.log('Fetched brands:', response.data);
                setBrands(response.data);
                if (response.data.length > 0) {
                    const randomBrand = response.data[Math.floor(Math.random() * response.data.length)];
                    setSelectedBrand({
                        name: randomBrand.name,
                        logo: randomBrand.logo || '/default-logo.png'
                    });
                } else {
                    throw new Error('No brands available');
                }
            } catch (error) {
                console.error('Error fetching brands:', error);
                setSelectedBrand({ name: 'Default Brand', logo: '/default-logo.png' });
            } finally {
                setIsLoading(false);
            }
        };
        fetchBrands();
    }, []);

    const startGame = async () => {
        if (currentBalance < bet) {
            toast({ title: "Error", description: "Not enough funds for a bet!" });
            return;
        }
        setIsPlaying(true);
        setVisibleData([]);
        setResult(null);
        setWinAmount(0);
        setCashedOut(false);
        setCurrentMultiplier(1);
        setData(generateData(1000));
        setCrashed(false);
        setGameEnded(false);
        const newBalance = currentBalance - bet;
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }
            const response = await axios.post('/api/users/update-balance', { coins: newBalance }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const updatedUser = response.data.user;
            setUser(updatedUser);
            setGlobalUser(updatedUser); // Обновляем глобальное состояние пользователя
            setCurrentBalance(updatedUser.coins);
        } catch (error) {
            console.error("Error updating user data:", error);
            toast({ title: "Error", description: "Failed to update balance. Please try again." });
            return; // Прерываем выполнение функции, если произошла ошибка
        }

        if (brands.length > 0) {
            const randomBrand = brands[Math.floor(Math.random() * brands.length)];
            setSelectedBrand({
                name: randomBrand.name,
                logo: randomBrand.logo || '/default-logo.png'
            });
        }
    };

    const cashOut = async () => {
        const winAmount = Math.floor(bet * currentMultiplier);
        const newBalance = currentBalance + winAmount;
        animateBalance(currentBalance, newBalance, 1000);
        setCurrentBalance(newBalance);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }
            const response = await axios.post('/api/users/update-balance', { coins: newBalance }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const updatedUser = response.data.user;
            setUser(updatedUser);
            setGlobalUser(updatedUser); // Обновляем глобальное состояние пользователя
            setResult(winAmount);
            setWinAmount(winAmount);
            setCashedOut(true);
        } catch (error) {
            console.error("Error updating user data:", error);
            toast({ title: "Error", description: "Failed to update balance. Please try again." });
            // Откатываем изменения в случае ошибки
            setCurrentBalance(currentBalance);
        }
    };

    const endGame = () => {
        clearInterval(timerRef.current);
        setIsPlaying(false);
        setCrashed(true);
        setGameEnded(true);
        if (!cashedOut) setResult(0);
    };

    useEffect(() => {
        if (isPlaying) {
            timerRef.current = setInterval(() => {
                setVisibleData(prevData => {
                    if (prevData.length >= data.length - 1) {
                        endGame();
                        return prevData;
                    }
                    const newDataPoint = data[prevData.length];
                    setCurrentMultiplier(newDataPoint.value);
                    if (newDataPoint.value === 1 && prevData[prevData.length - 1]?.value > 1) {
                        endGame();
                    }
                    return [...prevData, newDataPoint];
                });
            }, 100);
        }
        return () => clearInterval(timerRef.current);
    }, [isPlaying, data]);

    const renderButton = () => {
        const isDisabled = !isPlaying || cashedOut;
        const showLoss = gameEnded && !cashedOut;
        const buttonClass = `w-full ${showLoss ? 'bg-red-500 text-white' : 'bg-[#e0ff89] text-black'} hover:bg-[#d1ef7a] font-bold rounded-[30px] h-12 flex items-center justify-center`;

        return (
            <Button
                onClick={cashOut}
                disabled={isDisabled}
                className={buttonClass}
            >
                {isPlaying
                    ? (cashedOut ? `Win: ${winAmount}` : 'Cash Out')
                    : (gameEnded
                        ? (cashedOut ? `Win: ${winAmount}` : 'Loss')
                        : 'Cash Out')}
            </Button>
        );
    };

    const openBrandInfo = () => {
        setIsBrandInfoOpen(true);
    };

    return (
        <div style={gameContainerStyle}>
            <div className="text-white flex flex-col h-full p-4">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-2xl font-bold">Brand Growth</h2>
                    <button
                        onClick={onClose}
                        className="text-white cursor-pointer focus:outline-none"
                    >
                        <X size={24} />
                    </button>
                </div>
                <div className="flex items-center mb-4">
                    {isLoading ? (
                        <h2 className="text-lg font-bold">Loading brand...</h2>
                    ) : (
                        <>
                            <div onClick={openBrandInfo} className="flex items-center cursor-pointer">
                                <Avatar className="w-8 h-8 mr-2">
                                    <AvatarImage src={selectedBrand?.logo || '/default-logo.png'} alt={selectedBrand?.name || 'Default Brand'} />
                                    <AvatarFallback>{selectedBrand?.name?.[0] || 'D'}</AvatarFallback>
                                </Avatar>
                                <h2 className="text-lg font-bold flex-grow mr-2">{selectedBrand?.name || 'Default Brand'}</h2>
                                <div className="w-6 h-6 rounded-full flex items-center justify-center">
                                    <Info className="h-4 w-4 text-gray-400" />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {!isLoading && (
                    <div className="flex-grow mb-4 min-h-[200px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart data={visibleData.length > 0 ? visibleData : [{ time: 0, value: 1 }, { time: 100, value: MAX_MULTIPLIER }]}>
                                <XAxis dataKey="time" hide />
                                <YAxis hide domain={[1, MAX_MULTIPLIER]} />
                                <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                                <Area
                                    type="monotone"
                                    dataKey="value"
                                    stroke={crashed ? "#FF0000" : "#e0ff89"}
                                    fill={crashed ? "#FF0000" : "#e0ff89"}
                                    fillOpacity={0.3}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                )}
                <div className="flex justify-between items-center mb-4">
                    <div className="text-3xl font-bold">x{currentMultiplier.toFixed(2)}</div>
                    <div className="text-xl font-bold flex items-center">
                        {isAnimating ? animatingBalance : currentBalance}
                        <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                    </div>
                </div>
                <div className="flex justify-between gap-4 mb-4">
                    <div className="flex-grow">
                        <div className="w-full bg-black text-white border-2 border-[#e0ff89] rounded-[30px] h-12 flex items-center justify-between px-2">
                            <button
                                onClick={() => setBet(Math.max(10, bet - 10))}
                                disabled={isPlaying}
                                className="w-8 h-8 bg-[#e0ff89] rounded-full flex items-center justify-center"
                            >
                                <span className="text-black text-2xl font-bold leading-none">-</span>
                            </button>
                            <span className="text-xl font-bold">{bet}</span>
                            <button
                                onClick={() => setBet(bet + 10)}
                                disabled={isPlaying}
                                className="w-8 h-8 bg-[#e0ff89] rounded-full flex items-center justify-center"
                            >
                                <span className="text-black text-2xl font-bold leading-none">+</span>
                            </button>
                        </div>
                    </div>
                    <div className="w-1/3">
                        {renderButton()}
                    </div>
                </div>
                <Button
                    onClick={startGame}
                    disabled={isPlaying}
                    className="w-full bg-[#e0ff89] hover:bg-[#d1ef7a] text-black font-bold rounded-[30px] h-12"
                >
                    {isPlaying ? 'Game in progress...' : 'Start Trade'}
                </Button>
            </div>
            <Dialog open={isBrandInfoOpen} onOpenChange={setIsBrandInfoOpen}>
                <DialogContent className="bg-black rounded-[30px] p-6">
                    <DialogHeader>
                        <DialogTitle className="text-white flex items-center">
                            <Avatar className="w-8 h-8 mr-2">
                                <AvatarImage src={selectedBrand?.logo} alt={selectedBrand?.name} />
                                <AvatarFallback>{selectedBrand?.name?.[0]}</AvatarFallback>
                            </Avatar>
                            {selectedBrand?.name}
                        </DialogTitle>
                    </DialogHeader>
                    <div className="text-white mt-4">
                        <p><strong>Founded:</strong> {selectedBrand?.foundedYear}</p>
                        <p><strong>Country:</strong> {selectedBrand?.country}</p>
                        <p><strong>Key Product:</strong> {selectedBrand?.keyProduct}</p>
                        <p><strong>Fun Fact:</strong> {selectedBrand?.funFact}</p>
                        <p><strong>Description:</strong> {selectedBrand?.description}</p>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default BrandGrowthGame;