import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Card, CardContent } from "../ui/card";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog";
import { Pencil, Trash2 } from "lucide-react";
import { Label } from "../ui/label";

const RankForm = ({ rank, onSave, onCancel }) => {
    const [formData, setFormData] = useState(rank || {
        level: '',
        name: '',
        requiredCoins: '',
        battleLimit: '',
        status: '',
        backgroundImage: '',
        battlesRequired: '',
    });

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            const file = files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFormData(prev => ({ ...prev, [name]: reader.result }));
                };
                reader.readAsDataURL(file);
            }
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <Input name="level" value={formData.level} onChange={handleChange} placeholder="Level" required type="number" className="bg-[#15171c] text-[#d3d3d3]" />
            <Input name="name" value={formData.name} onChange={handleChange} placeholder="Rank Name" required className="bg-[#15171c] text-[#d3d3d3]" />
            <Input name="requiredCoins" value={formData.requiredCoins} onChange={handleChange} placeholder="Required Coins" required type="number" className="bg-[#15171c] text-[#d3d3d3]" />
            <Input name="battleLimit" value={formData.battleLimit} onChange={handleChange} placeholder="Battle Limit" required type="number" className="bg-[#15171c] text-[#d3d3d3]" />
            <Input name="status" value={formData.status} onChange={handleChange} placeholder="Status" required className="bg-[#15171c] text-[#d3d3d3]" />
            <Input name="battlesRequired" value={formData.battlesRequired} onChange={handleChange} placeholder="Battles Required" required type="number" className="bg-[#15171c] text-[#d3d3d3]" />
            <div>
                <Label htmlFor="backgroundImage" className="block text-sm font-medium text-[#d3d3d3]">Background Image</Label>
                <Input id="backgroundImage" name="backgroundImage" type="file" onChange={handleChange} accept="image/*" className="bg-[#15171c] text-[#d3d3d3]" />
                {formData.backgroundImage && (
                    <div className="relative mt-2">
                        <img src={formData.backgroundImage} alt="Background preview" className="h-32 w-full object-cover rounded" />
                        <Button
                            type="button"
                            onClick={() => setFormData(prev => ({ ...prev, backgroundImage: '' }))}
                            className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                        >
                            X
                        </Button>
                    </div>
                )}
            </div>
            <div className="flex justify-end space-x-2">
                <Button type="button" onClick={onCancel} variant="outline" className="bg-black text-white rounded-[30px] py-1 px-4">Cancel</Button>
                <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Save Rank</Button>
            </div>
        </form>
    );
};

const RankManagement = () => {
    const [ranks, setRanks] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingRank, setEditingRank] = useState(null);
    const [tabName, setTabName] = useState('Ranks');

    useEffect(() => {
        fetchRanks();
        const storedTabName = localStorage.getItem('rankTabName') || 'Ranks';
        setTabName(storedTabName);
    }, []);

    const fetchRanks = async () => {
        try {
            const response = await axios.get('/api/ranks');
            setRanks(response.data);
        } catch (error) {
            console.error('Error fetching ranks:', error);
        }
    };

    const handleSave = async (rankData) => {
        try {
            let response;
            if (editingRank) {
                response = await axios.put(`/api/ranks/${editingRank._id}`, rankData);
            } else {
                response = await axios.post('/api/ranks', rankData);
            }
            console.log('Rank saved:', response.data);
            fetchRanks();
            setIsDialogOpen(false);
            setEditingRank(null);
        } catch (error) {
            console.error('Error saving rank:', error.response ? error.response.data : error.message);
        }
    };

    const handleDelete = async (rankId) => {
        try {
            await axios.delete(`/api/ranks/${rankId}`);
            fetchRanks();
        } catch (error) {
            console.error('Error deleting rank:', error);
        }
    };

    const handleTabNameChange = (e) => {
        const newTabName = e.target.value;
        setTabName(newTabName);
        localStorage.setItem('rankTabName', newTabName);
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-6">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-[#eff0f2]">Rank</h2>
                    <Button onClick={() => setIsDialogOpen(true)} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Add New Rank</Button>
                </div>
                <div className="mb-4">
                    <Label htmlFor="tabName" className="block text-sm font-medium text-[#d3d3d3]">Tab Name on Main Page</Label>
                    <Input
                        id="tabName"
                        value={tabName}
                        onChange={handleTabNameChange}
                        className="mt-1 bg-[#15171c] text-[#d3d3d3]"
                        placeholder="Enter tab name"
                    />
                </div>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="text-[#d3d3d3]">Level</TableHead>
                            <TableHead className="text-[#d3d3d3]">Name</TableHead>
                            <TableHead className="text-[#d3d3d3]">Required Coins</TableHead>
                            <TableHead className="text-[#d3d3d3]">Battle Limit</TableHead>
                            <TableHead className="text-[#d3d3d3]">Status</TableHead>
                            <TableHead className="text-[#d3d3d3]">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {ranks.map(rank => (
                            <TableRow key={rank._id} className="hover:bg-black">
                                <TableCell className="text-[#d3d3d3]">{rank.level}</TableCell>
                                <TableCell className="text-[#d3d3d3]">{rank.name}</TableCell>
                                <TableCell className="text-[#d3d3d3]">{rank.requiredCoins}</TableCell>
                                <TableCell className="text-[#d3d3d3]">{rank.battleLimit}</TableCell>
                                <TableCell className="text-[#d3d3d3]">{rank.status}</TableCell>
                                <TableCell>
                                    <div className="flex space-x-2">
                                        <Button onClick={() => { setEditingRank(rank); setIsDialogOpen(true); }} className="p-2 bg-black text-white rounded-[30px]">
                                            <Pencil size={16} />
                                        </Button>
                                        <Button onClick={() => handleDelete(rank._id)} className="p-2 bg-black text-white rounded-[30px]">
                                            <Trash2 size={16} />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogContent className="max-h-[80vh] overflow-y-auto bg-[#15171c]">
                        <DialogHeader>
                            <DialogTitle className="text-[#eff0f2]">{editingRank ? 'Edit Rank' : 'Add New Rank'}</DialogTitle>
                            <DialogDescription className="text-[#d3d3d3]">
                                {editingRank ? 'Edit the details of the rank.' : 'Enter the details of the new rank.'}
                            </DialogDescription>
                        </DialogHeader>
                        <RankForm
                            rank={editingRank}
                            onSave={handleSave}
                            onCancel={() => setIsDialogOpen(false)}
                        />
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default RankManagement;