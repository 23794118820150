import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog";
import { Label } from "../ui/label";
import { Pencil, Trash2, Upload } from "lucide-react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Progress } from "../ui/progress";
import { useDropzone } from 'react-dropzone';

const ImageUpload = ({ onImageUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            onImageUpload(file);
        }
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer
        ${isDragActive ? 'border-blue-500 bg-blue-50' : 'hover:border-gray-400'}`}
        >
            <input {...getInputProps()} />
            <Upload className="mx-auto mb-4 text-gray-400" size={48} />
            <p className="text-sm text-gray-500">
                {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select one'}
            </p>
        </div>
    );
};

const ReferralForm = ({ referral, onSave, onCancel }) => {
    const [formData, setFormData] = useState(referral || {
        name: '',
        description: '',
        backgroundImage: '',
        coinsReward: '',
        battleLimitIncrease: '',
        battleRecoveryReduction: '',
        requiredReferrals: '',
    });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(referral?.backgroundImage || '');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleImageUpload = (file) => {
        setImageFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prev => ({ ...prev, backgroundImage: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData, imageFile);
    };

    const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="name" className="text-[#d3d3d3]">Name</Label>
                    <Input id="name" name="name" value={formData.name} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="description" className="text-[#d3d3d3]">Description</Label>
                    <Input id="description" name="description" value={formData.description} onChange={handleChange} required className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="coinsReward" className="text-[#d3d3d3]">Coins Reward</Label>
                    <Input id="coinsReward" name="coinsReward" type="number" value={formData.coinsReward} onChange={handleChange} className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="battleLimitIncrease" className="text-[#d3d3d3]">Limit Increase</Label>
                    <Input id="battleLimitIncrease" name="battleLimitIncrease" type="number" value={formData.battleLimitIncrease} onChange={handleChange} className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="battleRecoveryReduction" className="text-[#d3d3d3]">Recovery Reduction (seconds)</Label>
                    <Input id="battleRecoveryReduction" name="battleRecoveryReduction" type="number" value={formData.battleRecoveryReduction} onChange={handleChange} className={inputClasses} />
                </div>
                <div>
                    <Label htmlFor="requiredReferrals" className="text-[#d3d3d3]">Required Referrals</Label>
                    <Input id="requiredReferrals" name="requiredReferrals" type="number" value={formData.requiredReferrals} onChange={handleChange} required min="1" className={inputClasses} />
                </div>
            </div>
            <div>
                <Label className="text-[#d3d3d3]">Background Image</Label>
                <div className="flex flex-col space-y-2">
                    <ImageUpload onImageUpload={handleImageUpload} />
                </div>
                {imagePreview && (
                    <div className="relative mt-2">
                        <img src={imagePreview} alt="Background preview" className="h-32 w-full object-cover rounded" />
                        <Button
                            type="button"
                            onClick={() => {
                                setImagePreview('');
                                setFormData(prev => ({ ...prev, backgroundImage: '' }));
                            }}
                            className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                        >
                            X
                        </Button>
                    </div>
                )}
            </div>
            <div className="flex justify-end space-x-2">
                <Button type="button" onClick={onCancel} variant="outline" className="bg-black text-white border-[#282828] hover:bg-white hover:text-black transition-colors duration-200 rounded-[30px] py-1 px-4">Cancel</Button>
                <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Save Referral Task</Button>
            </div>
        </form>
    );
};

const ReferralsManagement = () => {
    const [referrals, setReferrals] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingReferral, setEditingReferral] = useState(null);

    const fetchReferrals = useCallback(async () => {
        try {
            const response = await axios.get('/api/referrals');
            console.log('Fetched referrals:', response.data); // Добавлено логирование
            setReferrals(response.data);
        } catch (error) {
            console.error('Error fetching referrals:', error);
        }
    }, []);

    useEffect(() => {
        fetchReferrals();
    }, [fetchReferrals]);

    useEffect(() => {
        console.log('Current referrals state:', referrals); // Добавлено логирование
    }, [referrals]);

    const handleSave = async (referralData, imageFile) => {
        try {
            const formData = new FormData();
            Object.keys(referralData).forEach(key => {
                if (key !== 'backgroundImage') {
                    formData.append(key, referralData[key]);
                }
            });

            if (imageFile) {
                formData.append('backgroundImage', imageFile);
            }

            let response;
            if (editingReferral) {
                response = await axios.put(`/api/referrals/${editingReferral._id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } else {
                response = await axios.post('/api/referrals', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }
            console.log('Referral saved:', response.data);
            fetchReferrals();
            setIsDialogOpen(false);
            setEditingReferral(null);
        } catch (error) {
            console.error('Error saving referral:', error);
            alert(`Error saving referral: ${error.message}`);
        }
    };

    const handleDelete = async (referralId) => {
        try {
            await axios.delete(`/api/referrals/${referralId}`);
            fetchReferrals();
        } catch (error) {
            console.error('Error deleting referral:', error);
        }
    };

    const onDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(referrals);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setReferrals(items);

        try {
            await axios.post('/api/referrals/reorder', { referrals: items.map(item => item._id) });
        } catch (error) {
            console.error('Error reordering referrals:', error);
            fetchReferrals();
        }
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-6">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-[#eff0f2]">Referrals</h2>
                    <Button onClick={() => setIsDialogOpen(true)} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Add New Referral Task</Button>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="referrals">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                                {referrals.map((referral, index) => (
                                    <Draggable key={referral._id} draggableId={referral._id} index={index}>
                                        {(provided) => (
                                            <Card
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="relative overflow-hidden rounded-lg shadow-lg bg-[#1c1f26]"
                                            >
                                                <div className="relative z-10 p-6 bg-black bg-opacity-60 h-full flex flex-col justify-between">
                                                    <div className="flex justify-between items-start">
                                                        <div>
                                                            <h3 className="text-2xl font-bold text-[#d3d3d3]">{referral.name}</h3>
                                                            <p className="text-sm text-[#a0a0a0] mt-1">{referral.description}</p>
                                                        </div>
                                                        <div className="text-right">
                                                            {referral.coinsReward && <p className="text-2xl font-bold text-[#e0ff89]">{referral.coinsReward} coins</p>}
                                                            {referral.battleLimitIncrease && <p className="text-sm text-[#d3d3d3]">+{referral.battleLimitIncrease} Limit</p>}
                                                            {referral.battleRecoveryReduction && <p className="text-sm text-[#d3d3d3]">-{Math.floor(referral.battleRecoveryReduction / 60)}m {referral.battleRecoveryReduction % 60}s Recovery</p>}
                                                        </div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <div className="w-full bg-gray-700 rounded-full h-4 relative">
                                                            <Progress
                                                                value={(referral.currentReferrals || 0) / referral.requiredReferrals * 100}
                                                                className="h-4 rounded-full bg-gray-300"
                                                                indicatorClassName="bg-white"
                                                            />
                                                            <div className="absolute inset-0 flex items-center justify-center text-xs text-black font-medium">
                                                                {referral.currentReferrals || 0} / {referral.requiredReferrals} referrals
                                                            </div>
                                                        </div>
                                                        <div className="mt-2 text-xs text-[#a0a0a0]">
                                                            Debug: currentReferrals={referral.currentReferrals}, requiredReferrals={referral.requiredReferrals}
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-end space-x-2 mt-4">
                                                        <Button onClick={() => { setEditingReferral(referral); setIsDialogOpen(true); }} className="p-2 bg-black text-white rounded-[30px]">
                                                            <Pencil size={16} />
                                                        </Button>
                                                        <Button onClick={() => handleDelete(referral._id)} className="p-2 bg-black text-white rounded-[30px]">
                                                            <Trash2 size={16} />
                                                        </Button>
                                                    </div>
                                                </div>
                                                {referral.backgroundImage && (
                                                    <img
                                                        src={referral.backgroundImage}
                                                        alt={referral.name}
                                                        className="absolute inset-0 w-full h-full object-cover opacity-30"
                                                    />
                                                )}
                                            </Card>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogContent className="max-h-[80vh] overflow-y-auto bg-[#15171c]">
                        <DialogHeader>
                            <DialogTitle className="text-[#eff0f2]">{editingReferral ? 'Edit Referral Task' : 'Add New Referral Task'}</DialogTitle>
                            <DialogDescription className="text-[#d3d3d3]">
                                {editingReferral ? 'Edit the details of the referral task.' : 'Enter the details of the new referral task.'}
                            </DialogDescription>
                        </DialogHeader>
                        <ReferralForm
                            referral={editingReferral}
                            onSave={handleSave}
                            onCancel={() => setIsDialogOpen(false)}
                        />
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default ReferralsManagement;