import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Card, CardContent } from "../ui/card";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog";
import { Pencil, Trash2, GripVertical, Plus, X } from "lucide-react";
import * as LucideIcons from 'lucide-react';

const ItemTypes = {
  CATEGORY: 'category',
};

const CategoryForm = ({ category, onSave, onCancel }) => {
  const [categoryName, setCategoryName] = useState(category?.name || '');
  const [subCategoriesList, setSubCategoriesList] = useState(category?.subCategories || []);
  const [newSubCategory, setNewSubCategory] = useState('');
  const [editingSubCategoryIndex, setEditingSubCategoryIndex] = useState(null);
  const [categoryColor, setCategoryColor] = useState(category?.color || '#000000');
  const [categoryTextColor, setCategoryTextColor] = useState(category?.textColor || '#ffffff');
  const [categoryIcon, setCategoryIcon] = useState(category?.icon && LucideIcons[category.icon] ? category.icon : 'Folder');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      _id: category?._id,
      name: categoryName,
      subCategories: subCategoriesList,
      color: categoryColor,
      textColor: categoryTextColor,
      icon: categoryIcon
    });
  };

  const addSubCategory = () => {
    if (newSubCategory && !subCategoriesList.includes(newSubCategory)) {
      setSubCategoriesList([...subCategoriesList, newSubCategory]);
      setNewSubCategory('');
    }
  };

  const removeSubCategory = (index) => {
    const updatedSubCategories = [...subCategoriesList];
    updatedSubCategories.splice(index, 1);
    setSubCategoriesList(updatedSubCategories);
  };

  const editSubCategory = (index, newValue) => {
    const updatedSubCategories = [...subCategoriesList];
    updatedSubCategories[index] = newValue;
    setSubCategoriesList(updatedSubCategories);
  };

  const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Input
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        placeholder="Category Name"
        required
        className={inputClasses}
      />
      <div>
  <h4 className="font-semibold mb-2 text-[#eff0f2]">Subcategories:</h4>
  <div className="mb-2 max-h-40 overflow-y-auto">
    {subCategoriesList.map((subCat, index) => (
      <div key={index} className="flex items-center justify-between mb-1">
        {editingSubCategoryIndex === index ? (
          <Input
            value={subCat}
            onChange={(e) => editSubCategory(index, e.target.value)}
            onBlur={() => setEditingSubCategoryIndex(null)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setEditingSubCategoryIndex(null);
              }
            }}
            className={`flex-grow mr-2 ${inputClasses}`}
            autoFocus
          />
        ) : (
          <span
            className="text-[#d3d3d3] cursor-pointer"
            onClick={() => setEditingSubCategoryIndex(index)}
          >
            {subCat}
          </span>
        )}
        <Button
          type="button"
          onClick={() => removeSubCategory(index)}
          className="p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
        >
          <X size={12} />
        </Button>
      </div>
    ))}
  </div>
  <div className="flex space-x-2">
    <Input
      value={newSubCategory}
      onChange={(e) => setNewSubCategory(e.target.value)}
      placeholder="New Subcategory"
      className={inputClasses}
    />
    <Button type="button" onClick={addSubCategory} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">
      <Plus size={16} />
    </Button>
  </div>
</div>
      <div className="flex space-x-4">
        <div className="flex-1">
          <h4 className="font-semibold mb-2 text-[#eff0f2]">Category Color:</h4>
          <div className="flex items-center">
            <Input
              type="color"
              value={categoryColor}
              onChange={(e) => setCategoryColor(e.target.value)}
              className={inputClasses}
            />
            <Input
              type="text"
              value={categoryColor}
              onChange={(e) => setCategoryColor(e.target.value)}
              className={inputClasses}
            />
          </div>
        </div>
        <div className="flex-1">
          <h4 className="font-semibold mb-2 text-[#eff0f2]">Text Color:</h4>
          <div className="flex items-center">
            <Input
              type="color"
              value={categoryTextColor}
              onChange={(e) => setCategoryTextColor(e.target.value)}
              className={inputClasses}
            />
            <Input
              type="text"
              value={categoryTextColor}
              onChange={(e) => setCategoryTextColor(e.target.value)}
              className={inputClasses}
            />
          </div>
        </div>
      </div>
      <div>
  <h4 className="font-semibold mb-2 text-[#eff0f2]">Icon:</h4>
  <div className="relative">
    <select
      value={categoryIcon}
      onChange={(e) => setCategoryIcon(e.target.value)}
      className={inputClasses}
    >
      {Object.keys(LucideIcons).map((iconName) => {
        return (
          <option key={iconName} value={iconName}>
            {iconName}
          </option>
        );
      })}
    </select>
    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
      {LucideIcons[categoryIcon] && React.createElement(LucideIcons[categoryIcon], { size: 20, className: "text-[#d3d3d3]" })}
    </div>
  </div>
</div>
      <div className="flex justify-end space-x-2">
        <Button type="button" onClick={onCancel} variant="outline" className="bg-black text-white rounded-[30px] py-1 px-4">Cancel</Button>
        <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Save Category</Button>
      </div>
    </form>
  );
};

const CategoryItem = ({ category, index, moveCategory, onEdit, onDelete }) => {
  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CATEGORY,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCategory(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CATEGORY,
    item: () => ({ id: category._id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const IconComponent = LucideIcons[category.icon] || LucideIcons.Folder;

  return (
    <TableRow ref={ref} style={{ opacity }} data-handler-id={handlerId} className="hover:bg-black">
      <TableCell className="w-8">
        <GripVertical size={16} className="text-[#d3d3d3] cursor-move" />
      </TableCell>
      <TableCell className="text-[#d3d3d3]">{category.name}</TableCell>
      <TableCell className="text-[#d3d3d3]">{category.subCategories.join(', ')}</TableCell>
      <TableCell>
        <div className="flex space-x-2">
          <div className="w-6 h-6 rounded-full" style={{ backgroundColor: category.color }}></div>
          <div className="w-6 h-6 rounded-full" style={{ backgroundColor: category.textColor }}></div>
        </div>
      </TableCell>
      <TableCell>
        <IconComponent size={24} className="text-[#d3d3d3]" />
      </TableCell>
      <TableCell>
        <div className="flex space-x-2">
          <Button onClick={() => onEdit(category)} className="p-2 bg-black text-white rounded-[30px]">
            <Pencil size={16} />
          </Button>
          <Button onClick={() => onDelete(category._id)} className="p-2 bg-black text-white rounded-[30px]">
            <Trash2 size={16} />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

const CategoriesManagement = () => {
  const [categories, setCategories] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCategories();
  }, []);

const fetchCategories = async () => {
  setIsLoading(true);
  try {
    const response = await axios.get('/api/categories');
    const sortedCategories = response.data.sort((a, b) => a.order - b.order);
    console.log('Fetched and sorted categories:', sortedCategories);
    setCategories(sortedCategories);
  } catch (error) {
    console.error('Error fetching categories:', error);
    setCategories([]);
  } finally {
    setIsLoading(false);
  }
};

  const handleSave = async (categoryData) => {
    try {
      if (categoryData._id) {
        await axios.put(`/api/categories/${categoryData._id}`, categoryData);
      } else {
        await axios.post('/api/categories', categoryData);
      }
      await fetchCategories();
      setIsDialogOpen(false);
      setEditingCategory(null);
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const handleDelete = async (categoryId) => {
    try {
      await axios.delete(`/api/categories/${categoryId}`);
      await fetchCategories();
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

const moveCategory = useCallback(
  async (dragIndex, hoverIndex) => {
    console.log(`Перемещение категории с индекса ${dragIndex} на индекс ${hoverIndex}`);
    const dragCategory = categories[dragIndex];
    const newCategories = [...categories];
    newCategories.splice(dragIndex, 1);
    newCategories.splice(hoverIndex, 0, dragCategory);
    
    // Обновляем порядок для всех категорий
    const updatedCategories = newCategories.map((category, index) => ({
      ...category,
      order: index
    }));
    
    console.log('Новый порядок категорий:', updatedCategories.map(c => ({ id: c._id, order: c.order })));
    setCategories(updatedCategories);

    try {
      const dataToSend = { 
        categories: updatedCategories.map(category => ({ _id: category._id, order: category.order }))
      };
      console.log('Отправляемые данные:', JSON.stringify(dataToSend, null, 2));
      
      const response = await axios.put('/api/categories/reorder', dataToSend);
      console.log('Ответ сервера:', response.data);
      
      if (response.status === 200) {
        setCategories(response.data);
      } else {
        throw new Error('Failed to reorder categories');
      }
    } catch (error) {
      console.error('Подробная ошибка при изменении порядка категорий:', error.response ? error.response.data : error);
      await fetchCategories();
    }
  },
  [categories, fetchCategories],
);

  return (
    <DndProvider backend={HTML5Backend}>
      <Card className="bg-transparent">
        <CardContent className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-[#eff0f2]">Categories</h2>
            <Button onClick={() => setIsDialogOpen(true)} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Add New Category</Button>
          </div>
          <div className="overflow-y-auto max-h-[calc(100vh-200px)]">
  {isLoading ? (
    <p className="text-[#d3d3d3] text-center">Loading categories...</p>
  ) : categories.length > 0 ? (
    categories.sort((a, b) => a.order - b.order).map((category, index) => (
      <CategoryItem
        key={category._id}
        category={category}
        index={index}
        moveCategory={moveCategory}
        onEdit={(category) => {
          setEditingCategory(category);
          setIsDialogOpen(true);
        }}
        onDelete={handleDelete}
      />
    ))
  ) : (
    <p className="text-[#d3d3d3] text-center">No categories available.</p>
  )}
</div>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogContent className="bg-[#15171c]">
              <DialogHeader>
                <DialogTitle className="text-[#eff0f2]">{editingCategory ? 'Edit Category' : 'Add New Category'}</DialogTitle>
                <DialogDescription className="text-[#d3d3d3]">
                  {editingCategory ? 'Edit the details of the category.' : 'Enter the details of the new category.'}
                </DialogDescription>
              </DialogHeader>
              <CategoryForm
                category={editingCategory}
                onSave={handleSave}
                onCancel={() => setIsDialogOpen(false)}
              />
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </DndProvider>
  );
};

export default CategoriesManagement;