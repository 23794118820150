import React, { useState, useEffect, useRef } from 'react';
import { Button } from "./button";
import { X, ChevronDown } from "lucide-react";

const SelectedCountry = ({ country, onRemove }) => (
  <div className="flex items-center gap-1 bg-[#15171c] text-white px-2 py-1 text-sm rounded-sm">
    {country.name}
    <Button 
      onClick={(e) => {
        e.stopPropagation();
        onRemove(country);
      }}
      variant="ghost" 
      size="sm"
      className="h-4 w-4 p-0 ml-1 text-white hover:bg-[#0f1014]"
    >
      <X className="h-3 w-3" />
    </Button>
  </div>
);

export const MultiSelect = React.memo(({ options, value, onChange, placeholder, className }) => {
  const [selectedValues, setSelectedValues] = useState(value || []);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setSelectedValues(value || []);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleValueChange = (selectedCountry) => {
    if (!selectedValues.some(country => country._id === selectedCountry._id)) {
      const newValues = [...selectedValues, selectedCountry];
      setSelectedValues(newValues);
      onChange(newValues);
    }
    setIsOpen(false);
  };

  const handleRemove = (countryToRemove) => {
    const newValues = selectedValues.filter(country => country._id !== countryToRemove._id);
    setSelectedValues(newValues);
    onChange(newValues);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="space-y-2 relative" ref={ref}>
      <div 
        onClick={toggleOpen}
        className={`w-full bg-black text-[#d3d3d3] border-[#282828] border-[1px] rounded-md p-2 cursor-pointer flex items-center justify-between ${className}`}
      >
        <div className="flex flex-wrap gap-1 max-h-20 overflow-y-auto">
          {selectedValues.length > 0 ? (
            selectedValues.map((country) => (
              <SelectedCountry 
                key={country._id}
                country={country}
                onRemove={handleRemove}
              />
            ))
          ) : (
            <span className="text-[#d3d3d3]">{placeholder}</span>
          )}
        </div>
        <ChevronDown className={`h-4 w-4 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-[#0f1014] border border-[#282828] rounded-md shadow-lg max-h-60 overflow-auto">
          {options.map((option) => (
            <div 
              key={option._id} 
              className="px-4 py-2 cursor-pointer hover:bg-[#15171c] text-[#d3d3d3]"
              onClick={() => handleValueChange(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default MultiSelect;