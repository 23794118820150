import React, { useState, useEffect } from 'react';
import { Avatar, AvatarImage, AvatarFallback } from "./ui/avatar";
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import AddBrand from './AddBrand';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./ui/dialog";

const UserProfile = ({ user, onAddBrand, onAddTask, onEditBrand, onDeleteBrand }) => {
  const [showAddBrandForm, setShowAddBrandForm] = useState(false);
  const [userBrands, setUserBrands] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deletingBrandId, setDeletingBrandId] = useState(null);
  const [editingBrandId, setEditingBrandId] = useState(null);

  useEffect(() => {
    const fetchUserBrands = async () => {
      try {
        const response = await axios.get(`/api/brands/user/${user._id}`);
        setUserBrands(response.data);
      } catch (error) {
        console.error('Error fetching user brands:', error);
      }
    };

    fetchUserBrands();
  }, [user._id]);

  const handleAddBrand = async (brandData) => {
    try {
      const response = await axios.post('/api/brands/user', brandData);
      console.log('Server response:', response.data);
      if (response.data.brand) {
        setUserBrands(prevBrands => [...prevBrands, response.data.brand]);
        setShowAddBrandForm(false);
        toast.success('Brand added successfully! It is pending approval.', {
          duration: 3000,
          position: 'top-center',
        });
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      console.error('Error adding brand:', error);
      toast.error(`Error adding brand: ${error.response?.data?.message || error.message || 'Unknown error'}`, {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleDeleteBrand = (brandId) => {
    setDeletingBrandId(brandId);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (deletingBrandId) {
      try {
        await axios.delete(`/api/brands/${deletingBrandId}`);
        setUserBrands(prevBrands => prevBrands.filter(brand => brand.id !== deletingBrandId));
        toast.success('Brand deleted successfully!', {
          duration: 3000,
          position: 'top-center',
        });
      } catch (error) {
        console.error('Error deleting brand:', error);
        toast.error(`Error deleting brand: ${error.response?.data?.message || error.message || 'Unknown error'}`, {
          duration: 3000,
          position: 'top-center',
        });
      }
      setIsDeleteDialogOpen(false);
      setDeletingBrandId(null);
    }
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const handleEditBrand = async (brandId, updatedBrandData) => {
    if (!updatedBrandData) {
      setEditingBrandId(brandId);
      return;
    }

    try {
      const response = await axios.put(`/api/brands/${brandId}`, updatedBrandData);
      if (response.data) {
        setUserBrands(prevBrands =>
          prevBrands.map(brand =>
            brand.id === brandId ? { ...brand, ...response.data } : brand
          )
        );
        setEditingBrandId(null);
        toast.success('Brand updated successfully!', {
          duration: 3000,
          position: 'top-center',
        });
      }
    } catch (error) {
      console.error('Error updating brand:', error);
      toast.error(`Error updating brand: ${error.response?.data?.message || error.message || 'Unknown error'}`, {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const StatCard = ({ title, value }) => (
    <Card className="bg-[#1a1a1a] rounded-[20px]">
      <CardContent className="p-4">
        <h3 className="text-sm font-semibold text-[#e0ff89]">{title}</h3>
        <p className="text-xl font-bold text-white">{value}</p>
      </CardContent>
    </Card>
  );

  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      <div className="flex-1 overflow-y-auto p-6">
        <div className="max-w-3xl mx-auto pb-20">
          <div className="flex items-center mb-8">
            <Avatar className="w-24 h-24 mr-6">
              {user.avatar ? (
                <AvatarImage src={user.avatar} alt="User Avatar" />
              ) : (
                <AvatarFallback>{getInitials(user.firstName || user.username || 'User')}</AvatarFallback>
              )}
            </Avatar>
            <div>
              <h2 className="text-3xl font-bold">{user.firstName || user.username || 'User'}</h2>
              <p className="text-xl text-gray-400">{user.rank || 'Beginner'}</p>
            </div>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-8">
            <StatCard title="Referral Users" value={user.referrals?.length || 0} />
            <StatCard title="Level" value={user.level || 1} />
            <StatCard title="Coins" value={user.coins || 0} />
            <StatCard title="Total Battles" value={user.battles || 0} />
          </div>

          {/* My Brands секция закомментирована
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-4">My Brands</h3>
            {userBrands.length > 0 ? (
              <div className="space-y-4">
                {userBrands.map((brand) => (
                  <div key={brand.id}>
                    <div className="bg-[#1a1a1a] rounded-[20px] p-4 flex items-center justify-between">
                      <div className="flex flex-col items-center mr-4">
                        <div className="w-16 h-16 rounded-full overflow-hidden mb-2">
                          <img src={brand.logo || '/default-brand-logo.png'} alt={brand.name} className="w-full h-full object-cover" />
                        </div>
                        <h4 className="font-semibold text-sm text-white text-center">{brand.name}</h4>
                      </div>
                      <div className="space-y-2 flex-grow">
                        <div className="flex space-x-2 mb-2">
                          <Button
                            className="flex-1 bg-white text-black hover:bg-gray-200 rounded-[30px] py-1 px-4"
                            disabled={brand.status === 'pending'}
                            onClick={() => handleEditBrand(brand.id)}
                          >
                            Edit
                          </Button>
                          <Button
                            className="flex-1 bg-white text-black hover:bg-gray-200 rounded-[30px] py-1 px-4"
                            disabled={brand.status === 'pending'}
                            onClick={() => handleDeleteBrand(brand.id)}
                          >
                            Delete
                          </Button>
                        </div>
                        <Button className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-1 px-4" disabled={brand.status === 'pending'}>
                          Statistics
                        </Button>
                        <p className="text-sm text-gray-400 text-center mt-1">
                          {brand.status === 'pending' ? 'Pending Approval' : brand.status || 'Status: Unknown'}
                        </p>
                      </div>
                    </div>
                    {editingBrandId === brand.id && (
                      <AddBrand
                        brand={{
                          ...brand,
                          category: brand.category?._id || brand.category,
                          country: brand.countries?.[0]?._id || (Array.isArray(brand.countries) && brand.countries.length > 0 ? brand.countries[0] : brand.country)
                        }}
                        onSave={(updatedBrand) => handleEditBrand(brand.id, updatedBrand)}
                        onCancel={() => setEditingBrandId(null)}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-400">No brands added yet.</p>
            )}
            <Button
              onClick={() => setShowAddBrandForm(true)}
              className="w-full mt-4 bg-[#e0ff89] text-black hover:bg-[#d1ef7a] text-lg py-3 rounded-[30px]"
            >
              Add Brand
            </Button>
          </div>

          {showAddBrandForm && (
            <AddBrand
              onSave={handleAddBrand}
              onCancel={() => setShowAddBrandForm(false)}
            />
          )}
          */}
        </div>
      </div>

      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="bg-black text-white rounded-[30px] p-6" style={{ zIndex: 1000 }}>
          <DialogHeader>
            <DialogTitle className="text-xl font-bold">Confirm Deletion</DialogTitle>
          </DialogHeader>
          <p className="text-[#d3d3d3]">
            Are you sure you want to delete this brand? This action cannot be undone.
          </p>
          <div className="mt-6 flex flex-row justify-between items-center">
            <Button
              onClick={confirmDelete}
              className="w-[48%] bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-2 px-4"
            >
              Delete
            </Button>
            <Button
              onClick={() => setIsDeleteDialogOpen(false)}
              className="w-[48%] bg-white text-black hover:bg-gray-200 rounded-[30px] py-2 px-4"
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UserProfile;