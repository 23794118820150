import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import store from './redux/store';
import BrandBattle from './BrandBattle';
import AdminLogin from './components/admin/AdminLogin';
import AdminPanel from './components/admin/AdminPanel';
import './styles/globals.css';
import Auth from './components/Auth';
import telegramService from './services/telegramService';
import LoadingScreen from './components/LoadingScreen';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [appReady, setAppReady] = useState(false);

  useEffect(() => {
    const initApp = async () => {
      // Имитация минимального времени загрузки
      await new Promise(resolve => setTimeout(resolve, 2000));

      const authenticateUser = async () => {
        try {
          console.log('Starting authentication process');
          // Проверяем, находимся ли мы на странице админ панели
          if (window.location.pathname.startsWith('/admin')) {
            console.log('Admin panel detected, skipping Telegram authentication');
            const savedUser = localStorage.getItem('user');
            const savedToken = localStorage.getItem('token');
            if (savedUser && savedToken) {
              setUser(JSON.parse(savedUser));
              axios.defaults.headers.common['Authorization'] = `Bearer ${savedToken}`;
            }
            return;
          }

          telegramService.init();
          const initData = telegramService.getInitData();
          let startParam = telegramService.getStartParam();

          console.log('Telegram init data:', initData);
          console.log('Start parameter:', startParam);

          const urlParams = new URLSearchParams(window.location.search);
          const urlStartParam = urlParams.get('start');
          if (urlStartParam) {
            startParam = urlStartParam;
            console.log('Start parameter from URL:', startParam);
          }

          if (startParam) {
            console.log('Processing start parameter:', startParam);
            try {
              const referralResponse = await axios.post('/api/users/process-referral', {
                startParam: startParam,
                initData: initData
              });
              console.log('Referral processing response:', referralResponse.data);
            } catch (error) {
              console.error('Error processing referral:', error);
            }
          }

          const savedUser = localStorage.getItem('user');
          const savedToken = localStorage.getItem('token');

          if (savedUser && savedToken) {
            console.log('Using saved user data');
            setUser(JSON.parse(savedUser));
            axios.defaults.headers.common['Authorization'] = `Bearer ${savedToken}`;
            return;
          }

          if (!initData) {
            console.log('No init data, redirecting to bot');
            window.location.href = 'https://t.me/BattleBrandsBot';
            return;
          }

          const userData = telegramService.getUserInfo();
          console.log('User data:', userData);

          console.log('Sending auth request to server');
          const response = await axios.post('/api/users/auth', {
            start_param: startParam,
            userData: userData
          }, {
            headers: {
              'X-Telegram-Init-Data': initData,
              'X-Start-Param': startParam
            }
          });
          console.log('Server response:', response.data);

          if (response.data && response.data.user) {
            const newUser = response.data.user;
            const token = response.data.token;
            setUser(newUser);
            localStorage.setItem('user', JSON.stringify(newUser));
            localStorage.setItem('token', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            console.log('User authenticated successfully');
          } else {
            throw new Error('Invalid user data received');
          }
        } catch (err) {
          console.error('Authentication failed:', err);
          const savedUser = localStorage.getItem('user');
          if (savedUser) {
            setUser(JSON.parse(savedUser));
          } else {
            setUser(null);
          }
        }
      };

      await authenticateUser();
      setLoading(false);
      setAppReady(true);
    };

    initApp();
  }, []);

  if (!appReady) {
    return <LoadingScreen />;
  }

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              style: {
                background: '#333',
                color: '#fff',
                borderRadius: '20px',
              },
            }}
          />
          <Routes>
            <Route path="/" element={
              user ? <BrandBattle user={user} setUser={setUser} /> : <Auth onLogin={setUser} />
            } />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/*" element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            } />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
};

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/admin/login" replace />;
  }
  return children;
};

export default App;