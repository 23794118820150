import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../ui/dialog";
import { Label } from "../ui/label";
import { Pencil, Trash2, Upload, ChevronLeft, ChevronRight, Globe, Smartphone } from "lucide-react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useDropzone } from 'react-dropzone';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

const ImageUpload = ({ onImageUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            onImageUpload(file);
        }
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });

    return (
        <div
            {...getRootProps()}
            className={`border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer
        ${isDragActive ? 'border-blue-500 bg-blue-50' : 'hover:border-gray-400'}`}
        >
            <input {...getInputProps()} />
            <Upload className="mx-auto mb-4 text-gray-400" size={48} />
            <p className="text-sm text-gray-500">
                {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select one'}
            </p>
        </div>
    );
};

const SpecialTaskForm = ({ task, onSave, onCancel }) => {
    const [formData, setFormData] = useState(task || {
        name: '',
        description: '',
        backgroundImage: '',
        coinsReward: '',
        taskType: 'app',
        specialUrl: '',
        confirmationCode: '',
        limitClicks: -1,
        limitClaims: -1,
    });

    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(task?.backgroundImage || '');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleImageUpload = (file) => {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prev => ({ ...prev, backgroundImage: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Submitting form data:', formData);
        onSave(formData, imageFile);
    };

    const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-4">
                <div>
                    <Label htmlFor="taskType" className="text-[#d3d3d3]">Task Type</Label>
                    <Select name="taskType" value={formData.taskType} onValueChange={(value) => handleChange({ target: { name: 'taskType', value } })}>
                        <SelectTrigger className={`${inputClasses} w-full`}>
                            <SelectValue placeholder="Select task type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="app">App</SelectItem>
                            <SelectItem value="web">Web</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label htmlFor="name" className="text-[#d3d3d3]">Name</Label>
                        <Input id="name" name="name" value={formData.name} onChange={handleChange} required className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="description" className="text-[#d3d3d3]">Description</Label>
                        <Input id="description" name="description" value={formData.description} onChange={handleChange} required className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="coinsReward" className="text-[#d3d3d3]">Coins Reward</Label>
                        <Input id="coinsReward" name="coinsReward" type="number" value={formData.coinsReward} onChange={handleChange} className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="specialUrl" className="text-[#d3d3d3]">Special URL</Label>
                        <Input id="specialUrl" name="specialUrl" value={formData.specialUrl} onChange={handleChange} required className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="limitClicks" className="text-[#d3d3d3]">Limit Clicks</Label>
                        <Input id="limitClicks" name="limitClicks" type="number" value={formData.limitClicks} onChange={handleChange} className={inputClasses} />
                    </div>
                    <div>
                        <Label htmlFor="limitClaims" className="text-[#d3d3d3]">Limit Claims</Label>
                        <Input id="limitClaims" name="limitClaims" type="number" value={formData.limitClaims} onChange={handleChange} className={inputClasses} />
                    </div>
                    {formData.taskType === 'web' && (
                        <>
                            <div>
                                <Label htmlFor="confirmationCode" className="text-[#d3d3d3]">Confirmation Code</Label>
                                <Input id="confirmationCode" name="confirmationCode" value={formData.confirmationCode} onChange={handleChange} className={inputClasses} />
                            </div>
                            <div>
                                <Label htmlFor="limitCodeEntry" className="text-[#d3d3d3]">Limit Code Entry</Label>
                                <Input id="limitCodeEntry" name="limitCodeEntry" type="number" value={formData.limitCodeEntry} onChange={handleChange} className={inputClasses} />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div>
                <Label className="text-[#d3d3d3]">Background Image</Label>
                <div className="flex flex-col space-y-2">
                    <ImageUpload onImageUpload={handleImageUpload} />
                </div>
                {imagePreview && (
                    <div className="relative mt-2">
                        <img src={imagePreview} alt="Background preview" className="h-32 w-full object-cover rounded" />
                        <Button
                            type="button"
                            onClick={() => {
                                setImagePreview('');
                                setFormData(prev => ({ ...prev, backgroundImage: '' }));
                            }}
                            className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                        >
                            X
                        </Button>
                    </div>
                )}
            </div>
            <div className="flex justify-end space-x-2">
                <Button type="button" onClick={onCancel} variant="outline" className="bg-black text-white border-[#282828] hover:bg-white hover:text-black transition-colors duration-200 rounded-[30px] py-1 px-4">Cancel</Button>
                <Button type="submit" className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Save Special Task</Button>
            </div>
        </form>
    );
};

const SpecialTasksManagement = () => {
    const [tasks, setTasks] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingTask, setEditingTask] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [tasksPerPage, setTasksPerPage] = useState(10);
    const [totalTasks, setTotalTasks] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletingTaskId, setDeletingTaskId] = useState(null);

    const fetchTasks = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/special-tasks?page=${currentPage}&limit=${tasksPerPage}`);
            console.log('Fetched special tasks:', response.data);
            if (Array.isArray(response.data)) {
                setTasks(response.data);
                setTotalTasks(response.data.length);
            } else if (response.data && Array.isArray(response.data.tasks)) {
                setTasks(response.data.tasks);
                setTotalTasks(response.data.totalTasks || 0);
            } else {
                console.error('Invalid response format:', response.data);
                setTasks([]);
                setTotalTasks(0);
            }
        } catch (error) {
            console.error('Error fetching special tasks:', error);
            setTasks([]);
            setTotalTasks(0);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, tasksPerPage]);

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    const handleSave = async (taskData, imageFile) => {
        try {
            const formData = new FormData();
            Object.keys(taskData).forEach(key => {
                if (key !== 'backgroundImage') {
                    formData.append(key, taskData[key]);
                }
            });

            if (imageFile) {
                formData.append('backgroundImage', imageFile);
            }

            console.log('Sending data:', Object.fromEntries(formData));

            let response;
            if (editingTask) {
                response = await axios.put(`/api/special-tasks/${editingTask._id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } else {
                response = await axios.post('/api/special-tasks', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }
            console.log('Special task saved:', response.data);
            fetchTasks();
            setIsDialogOpen(false);
            setEditingTask(null);
        } catch (error) {
            console.error('Error saving special task:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Status code:', error.response.status);
            }
            // Здесь можно добавить обработку ошибки, например, показать пользователю сообщение об ошибке
        }
    };

    const handleDelete = (taskId) => {
        setDeletingTaskId(taskId);
        setIsDeleteDialogOpen(true);
    };

    const confirmDelete = async () => {
        if (deletingTaskId) {
            try {
                await axios.delete(`/api/special-tasks/${deletingTaskId}`);
                fetchTasks();
            } catch (error) {
                console.error('Error deleting special task:', error);
            }
            setIsDeleteDialogOpen(false);
            setDeletingTaskId(null);
        }
    };

    const onDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(tasks);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setTasks(items);

        try {
            await axios.post('/api/special-tasks/reorder', { tasks: items.map(item => item._id) });
        } catch (error) {
            console.error('Error reordering special tasks:', error);
            fetchTasks();
        }
    };

    return (
        <Card className="bg-transparent">
            <CardContent className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-[#eff0f2]">Special Tasks</h2>
                    <Button onClick={() => setIsDialogOpen(true)} className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4">Add New Special Task</Button>
                </div>
                <div className="overflow-x-auto max-h-[calc(100vh-300px)] overflow-y-auto">
                    {isLoading ? (
                        <p className="text-[#d3d3d3] text-center">Loading tasks...</p>
                    ) : (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="tasks">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                                        {tasks && tasks.length > 0 ? (
                                            tasks.map((task, index) => (
                                                <Draggable key={task._id} draggableId={task._id} index={index}>
                                                    {(provided) => (
                                                        <Card
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="relative overflow-hidden rounded-lg shadow-lg bg-[#1c1f26]"
                                                        >
                                                            <div className="relative z-10 p-6 bg-black bg-opacity-60 h-full flex flex-col justify-between">
                                                                <div className="flex justify-between items-start">
                                                                    <div>
                                                                        <h3 className="text-2xl font-bold text-[#d3d3d3]">{task.name}</h3>
                                                                        <p className="text-sm text-[#a0a0a0] mt-1">{task.description}</p>
                                                                    </div>
                                                                    <div className="text-right">
                                                                        <p className="text-2xl font-bold text-white flex items-center">
                                                                            {task.coinsReward}
                                                                            <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                                                                        </p>
                                                                        <div className="flex items-center justify-end mt-1">
                                                                            <p className={`text-sm mr-2 ${task.status === 'active' ? 'text-[#e0ff89]' : 'text-red-500'}`}>
                                                                                {task.status === 'active' ? 'Active' : 'Expired'}
                                                                            </p>
                                                                            {task.taskType === 'app' ? <Smartphone className="w-4 h-4 text-white" /> : <Globe className="w-4 h-4 text-white" />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-4">
                                                                    <Button
                                                                        className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                                                        onClick={() => window.open(task.specialUrl, '_blank')}  // Изменено с task.url на task.specialUrl
                                                                        disabled={task.status === 'expired'}
                                                                    >
                                                                        {task.status === 'expired' ? 'Expired' : `Go to ${task.taskType === 'app' ? 'App' : 'Website'}`}
                                                                    </Button>
                                                                </div>
                                                                <div className="flex justify-between items-center mt-4">
                                                                    <div className="text-sm text-[#a0a0a0]">
                                                                        <p>Clicks: {task.clickCount}/{task.limitClicks === -1 ? '∞' : task.limitClicks}</p>
                                                                        <p>Completions: {task.completionCount}/{task.limitClaims === -1 ? '∞' : task.limitClaims}</p>
                                                                    </div>
                                                                    <div className="flex space-x-2">
                                                                        <Button onClick={() => { setEditingTask(task); setIsDialogOpen(true); }} className="p-2 bg-black text-white rounded-[30px]">
                                                                            <Pencil size={16} />
                                                                        </Button>
                                                                        <Button onClick={() => handleDelete(task._id)} className="p-2 bg-black text-white rounded-[30px]">
                                                                            <Trash2 size={16} />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {task.backgroundImage && (
                                                                <img
                                                                    src={task.backgroundImage}
                                                                    alt={task.name}
                                                                    className="absolute inset-0 w-full h-full object-cover opacity-30"
                                                                />
                                                            )}
                                                        </Card>
                                                    )}
                                                </Draggable>
                                            ))
                                        ) : (
                                            <p className="text-[#d3d3d3] text-center">No special tasks available.</p>
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </div>
                <div className="mt-4 flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <span className="text-[#d3d3d3]">Tasks per page:</span>
                        <Select value={tasksPerPage.toString()} onValueChange={(value) => setTasksPerPage(Number(value))}>
                            <SelectTrigger className="w-[70px] bg-black text-[#d3d3d3]">
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className="bg-black text-[#d3d3d3]">
                                {[10, 20, 50, 100].map(value => (
                                    <SelectItem key={value} value={value.toString()}>{value}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-full p-2"
                        >
                            <ChevronLeft size={16} />
                        </Button>
                        <span className="text-[#d3d3d3]">Page {currentPage} of {Math.ceil(totalTasks / tasksPerPage)}</span>
                        <Button
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(totalTasks / tasksPerPage)))}
                            disabled={currentPage === Math.ceil(totalTasks / tasksPerPage)}
                            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-full p-2"
                        >
                            <ChevronRight size={16} />
                        </Button>
                    </div>
                </div>
            </CardContent>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent className="max-h-[80vh] overflow-y-auto bg-[#15171c]">
                    <DialogHeader>
                        <DialogTitle className="text-[#eff0f2]">{editingTask ? 'Edit Special Task' : 'Add New Special Task'}</DialogTitle>
                        <DialogDescription className="text-[#d3d3d3]">
                            {editingTask ? 'Edit the details of the special task.' : 'Enter the details of the new special task.'}
                        </DialogDescription>
                    </DialogHeader>
                    <SpecialTaskForm
                        task={editingTask}
                        onSave={handleSave}
                        onCancel={() => setIsDialogOpen(false)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
                <DialogContent className="bg-black text-white rounded-[30px] p-6">
                    <DialogHeader>
                        <DialogTitle className="text-xl font-bold">Confirm Deletion</DialogTitle>
                        <DialogDescription className="text-[#d3d3d3]">
                            Are you sure you want to delete this special task? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="mt-6 flex justify-end space-x-4">
                        <Button
                            onClick={() => setIsDeleteDialogOpen(false)}
                            className="bg-white text-black hover:bg-gray-200 rounded-[30px] py-1 px-4"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={confirmDelete}
                            className="bg-red-500 text-white hover:bg-red-600 rounded-[30px] py-1 px-4"
                        >
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </Card>
    );
};

export default SpecialTasksManagement;