import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Card, CardContent, CardFooter } from "./components/ui/card";
import { Button } from "./components/ui/button";
import { Avatar, AvatarImage, AvatarFallback } from "./components/ui/avatar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./components/ui/dialog";
import { Home, BarChart2, Gift, Users, Share2, Copy, ChevronDown, ChevronUp, ChevronRight, Video, Star, X, ShoppingCart, Smartphone, Globe } from "lucide-react";
import * as LucideIcons from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Input } from "./components/ui/input";
import './BrandBattle.css';
import ReactCountryFlag from "react-country-flag";
import { Progress } from "./components/ui/progress";
import BrandAvatar from './components/BrandAvatar';
import { Toast } from "./components/ui/toast";
import { useToast } from "./components/ui/use-toast";
import AnimatedBackground from './components/AnimatedBackground';
import BrandGrowthGame from './components/BrandGrowthGame';
import UserProfile from './components/UserProfile';
import { SiDiscord, SiFacebook, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiTencentqq, SiReddit, SiSnapchat, SiTelegram, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiSinaweibo, SiWhatsapp, SiX, SiYoutube } from 'react-icons/si';

const BrandBattle = ({ user: initialUser, setUser: setGlobalUser }) => {
  console.log('BrandBattle component started', { initialUser });
  const [toastMessage, setToastMessage] = useState(null);
  const [userData, setUserData] = useState(initialUser || {});
  const [stats, setStats] = useState({ battlesWon: initialUser?.stats?.battlesWon || 0, brandsFaced: [] });
  const [score, setScore] = useState(initialUser?.coins || 0);
  const [battlesWon, setBattlesWon] = useState(initialUser?.stats?.battlesWon || 0);
  const [availableBattles, setAvailableBattles] = useState(0);
  const [battleLimit, setBattleLimit] = useState(initialUser?.battleLimit || 50);
  const [remainingBattles, setRemainingBattles] = useState(initialUser?.availableBattles || 0);
  const [level, setLevel] = useState(initialUser?.level || 1);
  const [brandsLoaded, setBrandsLoaded] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState({});
  const [ranks, setRanks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('Global');
  const [countries, setCountries] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [referralLink, setReferralLink] = useState(userData.referralLink || '');
  const [currentRank, setCurrentRank] = useState(null);
  const [selectedBrandIndex, setSelectedBrandIndex] = useState(null);
  const [openFaqId, setOpenFaqId] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedMainCategory, setSelectedMainCategory] = useState('');
  const [isCategoryDialogOpen, setIsCategoryDialogOpen] = useState(false);
  const [isCountryDialogOpen, setIsCountryDialogOpen] = useState(false);
  const [isReferralDialogOpen, setIsReferralDialogOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [showUpgradeAnimation, setShowUpgradeAnimation] = useState(false);
  const [levelProgress, setLevelProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [referrals, setReferrals] = useState([]);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [selectedBrandInfo, setSelectedBrandInfo] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const [videoTasks, setVideoTasks] = useState([]);
  const [socialTasks, setSocialTasks] = useState([]);
  const [specialTasks, setSpecialTasks] = useState([]);
  const [taskCodes, setTaskCodes] = useState({});
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [leaderboardPeriod, setLeaderboardPeriod] = useState('week');
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const getSocialIcon = (platform) => {
    const Icon = socialIcons[platform];
    return Icon ? <Icon className="w-6 h-6 text-white" /> : null;
  };

  const socialIcons = {
    Discord: SiDiscord,
    Facebook: SiFacebook,
    Instagram: SiInstagram,
    KakaoTalk: SiKakaotalk,
    Line: SiLine,
    LinkedIn: SiLinkedin,
    Medium: SiMedium,
    Ok: SiOdnoklassniki,
    Onlyfans: SiOnlyfans,
    Patreon: SiPatreon,
    Pinterest: SiPinterest,
    QQ: SiTencentqq,
    Reddit: SiReddit,
    Snapchat: SiSnapchat,
    Telegram: SiTelegram,
    Threads: SiThreads,
    TikTok: SiTiktok,
    Tumblr: SiTumblr,
    Twitch: SiTwitch,
    Viber: SiViber,
    Vk: SiVk,
    WeChat: SiWechat,
    Weibo: SiSinaweibo,
    WhatsApp: SiWhatsapp,
    X: SiX,
    YouTube: SiYoutube,
  };

  useEffect(() => {
    if (initialUser?.remainingBattles !== undefined) {
      setRemainingBattles(initialUser.remainingBattles);
    }
  }, [initialUser]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const telegramInitData = window.Telegram?.WebApp?.initData || localStorage.getItem('telegramInitData');
        if (!telegramInitData) {
          throw new Error('No Telegram init data found');
        }
        console.log('Telegram init data:', telegramInitData);
        localStorage.setItem('telegramInitData', telegramInitData);
        const parsedInitData = Object.fromEntries(new URLSearchParams(telegramInitData));
        const startParam = parsedInitData.start_param || window.Telegram?.WebApp?.initDataUnsafe?.start_param || new URLSearchParams(window.location.search).get('start');
        console.log('Start parameter:', startParam);
        const response = await axios.post('/api/users/auth', { telegramInitData, start: startParam });
        console.log('Auth response:', response.data);
        const { user, token } = response.data;
        localStorage.setItem('token', token);
        updateUserState(user);
        setAvailableBattles(user.availableBattles);
        if (user.referralLink) {
          console.log('Setting referral link:', user.referralLink);
          setReferralLink(user.referralLink);
        } else {
          console.error('User data does not contain referralLink');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (initialUser && initialUser.referralLink) {
          console.log('Using initial user referral link:', initialUser.referralLink);
          setReferralLink(initialUser.referralLink);
        } else {
          console.error('No referral link available');
        }
      } finally {
        setIsUserDataLoaded(true);
      }
    };
    fetchUserData();
  }, []);

  const getOrderedBrands = useCallback((allBrands, count = 2) => {
    const filteredBrands = allBrands.filter(brand => {
      const countryMatch = selectedCountry === 'Global'
        ? brand.global
        : (!selectedCountry || (brand.countries && brand.countries.some(c => c.code === selectedCountry)));
      const categoryMatch = !selectedMainCategory || brand.category === selectedMainCategory;
      const subCategoryMatch = !selectedSubCategory || brand.subCategory === selectedSubCategory;
      return countryMatch && categoryMatch && subCategoryMatch;
    });

    const shuffledBrands = filteredBrands.sort(() => 0.5 - Math.random());
    return shuffledBrands.slice(0, count);
  }, [selectedCountry, selectedMainCategory, selectedSubCategory]);

  // Функция для получения брендов с учетом выбранной страны и категории
const fetchBrands = useCallback(async () => {
  try {
    const [brandsResponse, categoriesResponse] = await Promise.all([
      axios.get('/api/brands'),
      axios.get('/api/categories')
    ]);
    const allBrands = brandsResponse.data.brands || [];
    const allCategories = categoriesResponse.data;
    setBrands(allBrands);
    setCategories(allCategories.reduce((acc, category) => {
      if (category && category._id) {
        acc[category._id] = {
          ...category,
          subCategories: Array.isArray(category.subCategories) ? category.subCategories : []
        };
      }
      return acc;
    }, {}));
    const availableCountries = ['Global', ...new Set(allBrands.reduce((acc, brand) => {
      if (brand.countries && Array.isArray(brand.countries)) {
        acc.push(...brand.countries.map(c => c.code).filter(Boolean));
      }
      return acc;
    }, []))];
    setCountries(prevCountries => Array.isArray(prevCountries) ? prevCountries.filter(country => availableCountries.includes(country.code)) : []);
    return allBrands;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}, []);

const getFilteredBrands = useCallback(() => {
  console.log('Filtering brands:', { selectedCountry, selectedMainCategory, selectedSubCategory, brands });
  if (!Array.isArray(brands)) {
    console.error('brands is not an array:', brands);
    return [];
  }
  return brands.filter(brand => {
    const countryMatch = selectedCountry === 'Global' ?
      brand.global :
      (brand.countries && Array.isArray(brand.countries) && brand.countries.some(c => c.code === selectedCountry));
    const categoryMatch = !selectedMainCategory ||
      (brand.categories && Array.isArray(brand.categories) && brand.categories.some(cat => cat._id === selectedMainCategory));
    const subCategoryMatch = !selectedSubCategory ||
      (brand.subCategories && Array.isArray(brand.subCategories) && brand.subCategories.includes(selectedSubCategory));
    return countryMatch && categoryMatch && subCategoryMatch;
  });
}, [brands, selectedCountry, selectedMainCategory, selectedSubCategory]);

  const getTwoRandomBrands = useCallback((filteredBrands) => {
    const shuffled = [...filteredBrands].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 2);
  }, []);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get('/api/brands');
        const allBrands = response.data;
        console.log('Loaded brands:', allBrands);

        // Фильтруем только активные бренды
        const filteredBrands = allBrands.filter(brand => brand.status === 'active');

        console.log('Filtered active brands:', filteredBrands);
        setBrands(prevBrands => {
          // Объединяем существующие бренды с новыми активными брендами
          const updatedBrands = [...prevBrands];
          filteredBrands.forEach(newBrand => {
            if (!updatedBrands.some(existingBrand => existingBrand._id === newBrand._id)) {
              updatedBrands.push(newBrand);
            }
          });
          return updatedBrands;
        });

        if (selectedBrands.length === 0) {
          setSelectedBrands(getTwoRandomBrands(filteredBrands));
        }
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    if (brands.length === 0) {
      fetchBrands();
    }
  }, [brands.length, selectedBrands.length]);

  const updateSelectedBrands = useCallback(() => {
    if (brands.length > 0) {
      const filteredBrands = getFilteredBrands();
      if (filteredBrands.length > 0) {
        const newBrands = getTwoRandomBrands(filteredBrands);
        setSelectedBrands(newBrands);
      } else {
        setSelectedBrands([]);
        setToastMessage({ title: "Info", description: "No brands match the current filters." });
      }
    }
  }, [brands, getFilteredBrands, getTwoRandomBrands, setToastMessage]);

  useEffect(() => {
    if (brands.length > 0) {
      updateSelectedBrands();
    }
  }, [brands, selectedCountry, selectedMainCategory, selectedSubCategory, updateSelectedBrands]);

  const updateUserDataCallback = useCallback(async (updatedData) => {
    try {
      const response = await axios.put('/api/users/me', updatedData, {
        headers: {
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });
      setGlobalUser(response.data);
      setUserData(prevData => ({ ...prevData, ...response.data }));
      setScore(response.data.coins);
      setBattlesWon(response.data.battles);
      setRemainingBattles(response.data.availableBattles);
      setLevel(response.data.level);
    } catch (error) {
      console.error('Error updating user data:', error);
      setToastMessage({ title: "Error", description: "Failed to update user data. Please try again." });
    }
  }, [setGlobalUser]);

  useEffect(() => {
    const initialFetch = async () => {
      if (!brandsLoaded) {
        const fetchedBrands = await fetchBrands();
        if (fetchedBrands.length > 0 && selectedBrands.length === 0) {
          const newBrands = getOrderedBrands(fetchedBrands, 2);
          setSelectedBrands(newBrands);
        }
        setBrandsLoaded(true);
      }
    };
    initialFetch();
  }, [fetchBrands, getOrderedBrands, brandsLoaded, selectedBrands.length]);

  const handleAuth = async (authData) => {
    try {
      console.log('Attempting authentication with data:', authData);
      const response = await axios.post('/api/users/auth', { telegramInitData: authData }, {
        headers: { 'Content-Type': 'application/json' }
      });
      const { user, token } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('telegramInitData', authData);
      updateUserData(user);
      setGlobalUser(user);
      console.log('Authentication successful, token saved:', token);
      return token;
    } catch (error) {
      console.error('Authentication error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      throw error;
    }
  };

  const refreshUserData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await axios.get('/api/users/me', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const updatedUser = response.data;
        setUserData(updatedUser);
        setGlobalUser(updatedUser);
        updateUserData({
          coins: updatedUser.coins,
          battles: updatedUser.battles,
          battleLimit: updatedUser.totalBattles - updatedUser.currentBattles,
          level: updatedUser.level
        });
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  }, [setGlobalUser]);

  const updateUserState = (user) => {
    console.log('Updating user state with:', user);
    if (user && Object.keys(user).length > 0) {
      setUserData(user);
      setScore(user.coins || 0);
      setBattlesWon(user.battles || 0);
      setStats({ battlesWon: user.battles || 0, brandsFaced: [] });
      // Используем currentBattles и totalBattles вместо availableBattles
      const availableBattles = user.totalBattles - user.currentBattles;
      setRemainingBattles(availableBattles);
      setBattleLimit(user.totalBattles || 50);
      setLevel(user.level || 1);
      setGlobalUser(user);
      console.log('Updated user state:', {
        coins: user.coins,
        battles: user.battles,
        availableBattles: availableBattles,
        totalBattles: user.totalBattles,
        currentBattles: user.currentBattles,
        level: user.level
      });
    } else {
      console.warn('Attempted to update user state with invalid data:', user);
    }
  };

  useEffect(() => {
    if (!isUserDataLoaded) {
      return;
    }

    const initAuth = async () => {
      const telegramInitData = window.Telegram?.WebApp?.initData || localStorage.getItem('telegramInitData');
      if (telegramInitData) {
        try {
          console.log('Initializing auth with telegramInitData');
          const response = await axios.post('/api/users/auth', { telegramInitData });
          console.log('Auth response:', response.data);
          const { user, token } = response.data;
          localStorage.setItem('token', token);
          localStorage.setItem('telegramInitData', telegramInitData);
          updateUserState(user);
        } catch (error) {
          console.error('Error during authentication:', error);
        }
      } else {
        console.warn('No telegramInitData available');
      }
    };

    initAuth();
  }, [isUserDataLoaded]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const [videoResponse, socialResponse, specialResponse] = await Promise.all([
          axios.get('/api/video-tasks'),
          axios.get('/api/social-tasks'),
          axios.get('/api/special-tasks')
        ]);
        const updatedVideoTasks = videoResponse.data.map(task => ({
          ...task,
          status: userData.completedVideoTasks?.includes(task._id) ? 'completed' : task.status || 'not_started'
        }));
        const updatedSocialTasks = socialResponse.data.map(task => ({
          ...task,
          status: userData.completedSocialTasks?.includes(task._id) ? 'completed' : task.status || 'not_started'
        }));
        const updatedSpecialTasks = specialResponse.data.map(task => ({
          ...task,
          status: userData.completedSpecialTasks?.includes(task._id) ? 'completed' : task.status || 'not_started'
        }));
        setVideoTasks(updatedVideoTasks);
        setSocialTasks(updatedSocialTasks);
        setSpecialTasks(updatedSpecialTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };
    fetchTasks();
  }, [userData]);

  const handleStartVideoTask = async (taskId) => {
    try {
      const response = await axios.post(`/api/video-tasks/${taskId}/start`);
      setVideoTasks(prevTasks =>
        prevTasks.map(task =>
          task._id === taskId ? { ...task, status: 'in_progress' } : task
        )
      );
      setTimeout(() => {
        setVideoTasks(prevTasks =>
          prevTasks.map(task =>
            task._id === taskId ? { ...task, status: 'ready_to_claim' } : task
          )
        );
      }, response.data.timerDuration * 1000);
    } catch (error) {
      console.error('Error starting video task:', error);
      setToastMessage({ title: "Error", description: "Failed to start video task. Please try again." });
    }
  };

  const handleCodeInput = (taskId, code) => {
    setTaskCodes(prevCodes => ({ ...prevCodes, [taskId]: code }));
    setVideoTasks(prevTasks =>
      prevTasks.map(task =>
        task._id === taskId ? { ...task, codeError: false } : task
      )
    );
  };

  const handleClaimVideoTask = async (taskId) => {
    try {
      const code = taskCodes[taskId];
      const task = videoTasks.find(t => t._id === taskId);
      if (!task) {
        console.error('Task not found');
        setToastMessage({ title: "Error", description: "Task not found. Please try again." });
        return;
      }
      if (!code) {
        setToastMessage({ title: "Error", description: "Please enter a code." });
        return;
      }
      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');
      if (!token || !telegramInitData) {
        setToastMessage({ title: "Error", description: "Authentication error. Please refresh the page and try again." });
        return;
      }
      console.log('Sending claim request with:', { taskId, code, token: token.substring(0, 10) + '...' });
      const response = await axios.post(`/api/video-tasks/${taskId}/claim`,
        { code },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-Telegram-Init-Data': telegramInitData
          }
        }
      );
      console.log('Claim response:', response.data);
      if (response.data.success) {
        setVideoTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'completed', codeError: false } : t
          )
        );
        const updatedUserData = {
          ...userData,
          coins: response.data.updatedUser.coins,
          completedVideoTasks: response.data.updatedUser.completedVideoTasks
        };
        setUserData(updatedUserData);
        setGlobalUser(updatedUserData);
        setScore(updatedUserData.coins);
        setToastMessage({ title: "Success", description: `Task completed successfully! You earned ${task.coinsReward} coins.` });
      } else {
        console.error('Claim failed:', response.data);
        switch (response.data.errorType) {
          case 'INVALID_CODE':
            setVideoTasks(prevTasks =>
              prevTasks.map(t =>
                t._id === taskId ? { ...t, codeError: true } : t
              )
            );
            setToastMessage({ title: "Error", description: "Invalid code. Please try again." });
            break;
          case 'TASK_COMPLETED':
            setToastMessage({ title: "Info", description: "You have already completed this task." });
            setVideoTasks(prevTasks =>
              prevTasks.map(t =>
                t._id === taskId ? { ...t, status: 'completed' } : t
              )
            );
            break;
          case 'TASK_EXPIRED':
            setToastMessage({ title: "Error", description: "This task has expired." });
            setVideoTasks(prevTasks =>
              prevTasks.map(t =>
                t._id === taskId ? { ...t, status: 'expired' } : t
              )
            );
            break;
          default:
            setToastMessage({ title: "Error", description: response.data.message || "Failed to claim task. Please try again." });
        }
      }
    } catch (error) {
      console.error('Error claiming video task:', error);
      if (error.response && error.response.data) {
        console.error('Error response:', error.response.data);
        setToastMessage({ title: "Error", description: error.response.data.message || "Failed to claim task. Please try again." });
      } else {
        setToastMessage({ title: "Error", description: "Network error. Please check your connection and try again." });
      }
    }
  };

  const handleWatchVideo = async (taskId, videoUrl) => {
    try {
      const task = videoTasks.find(t => t._id === taskId);
      if (!task) {
        console.error('Task not found');
        return;
      }

      const token = localStorage.getItem('token');
      const telegramInitData = localStorage.getItem('telegramInitData');

      if (!token || !telegramInitData) {
        setToastMessage({ title: "Error", description: "Authentication error. Please refresh the page and try again." });
        return;
      }

      // Отправляем запрос на сервер для регистрации клика
      const response = await axios.post(`/api/video-tasks/${taskId}/click`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Telegram-Init-Data': telegramInitData
        }
      });

      if (response.data.success) {
        // Открываем видео
        window.open(videoUrl, '_blank');

        setVideoTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'in_progress' } : t
          )
        );

        setToastMessage({ title: "Success", description: "Video opened. Watching in progress..." });

        // Используем таймер из задания
        const timerDuration = task.timerDuration * 1000;
        setTimeout(() => {
          setVideoTasks(prevTasks =>
            prevTasks.map(t =>
              t._id === taskId ? { ...t, status: 'ready_to_claim' } : t
            )
          );
          setToastMessage({ title: "Success", description: "Video watched. You can now enter the code." });
        }, timerDuration);
      } else {
        setToastMessage({ title: "Info", description: response.data.message || "You've already clicked this video." });
      }
    } catch (error) {
      console.error('Error registering video click:', error);
      if (error.response && error.response.data) {
        setToastMessage({ title: "Error", description: error.response.data.message || "Failed to register video click. Please try again." });
      } else {
        setToastMessage({ title: "Error", description: "Failed to register video click. Please try again." });
      }
    }
  };

  const handleSubscribe = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      // Отправляем запрос на сервер для регистрации клика
      const response = await axios.post(`/api/social-tasks/${taskId}/click`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });

      if (response.data.success) {
        // Открываем ссылку на канал в новом окне
        window.open(task.socialUrl, '_blank');

        // Меняем статус задачи на 'subscribed', чтобы показать кнопку Claim
        setSocialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'subscribed' } : t
          )
        );
      } else {
        setToastMessage({ title: "Info", description: response.data.message || "You've already clicked this task." });
      }
    } catch (error) {
      console.error('Error subscribing to social task:', error);
      setToastMessage({ title: "Error", description: "Failed to subscribe. Please try again." });
    }
  };

  const handleClaimSocialTask = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      // Проверяем подписку через бота
      const checkSubscriptionResponse = await axios.post('/api/social-tasks/check-subscription', {
        userId: userData.telegramId,
        channelUsername: new URL(task.socialUrl).pathname.slice(1) // Извлекаем username из URL
      });

      if (checkSubscriptionResponse.data.isSubscribed) {
        const response = await axios.post(`/api/social-tasks/${taskId}/claim`);
        if (response.data.success) {
          setSocialTasks(prevTasks =>
            prevTasks.map(t =>
              t._id === taskId ? { ...t, status: 'completed' } : t
            )
          );
          const updatedUserData = {
            ...userData,
            coins: response.data.updatedUser.coins,
            completedSocialTasks: response.data.updatedUser.completedSocialTasks
          };
          setUserData(updatedUserData);
          setGlobalUser(updatedUserData);
          setScore(updatedUserData.coins);
          setToastMessage({ title: "Success", description: `Task completed successfully! You earned ${task.coinsReward} coins.` });
        } else {
          setToastMessage({ title: "Error", description: response.data.message || "Failed to claim task. Please try again." });
        }
      } else {
        setToastMessage({ title: "Error", description: "You are not subscribed to the channel. Please subscribe and try again." });
      }
    } catch (error) {
      console.error('Error claiming social task:', error);
      setToastMessage({ title: "Error", description: error.response?.data?.message || "Failed to claim task. Please try again." });
    }
  };

  const handleSpecialTask = async (taskId) => {
    try {
      const task = specialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      const response = await axios.post(`/api/special-tasks/${taskId}/click`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });

      if (response.data.success) {
        window.open(task.specialUrl, '_blank');
        setSpecialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'in_progress' } : t
          )
        );
      }
    } catch (error) {
      console.error('Error handling special task:', error);
      setToastMessage({ title: "Error", description: "Failed to start special task. Please try again." });
    }
  };

  const handleClaimSpecialTask = async (taskId) => {
    try {
      const task = specialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }

      let requestData = {};
      if (task.taskType === 'web') {
        const code = taskCodes[taskId];
        if (!code) {
          setToastMessage({ title: "Error", description: "Please enter a code." });
          return;
        }
        requestData = { code };
      }

      const response = await axios.post(`/api/special-tasks/${taskId}/claim`,
        requestData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
          }
        }
      );

      if (response.data.success) {
        const updatedUserData = {
          ...userData,
          coins: response.data.updatedUser.coins,
          completedSpecialTasks: response.data.updatedUser.completedSpecialTasks
        };
        setUserData(updatedUserData);
        setGlobalUser(updatedUserData);
        setScore(updatedUserData.coins);

        const updatedTasksResponse = await axios.get('/api/special-tasks', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
          }
        });

        const updatedSpecialTasks = updatedTasksResponse.data.map(task => ({
          ...task,
          status: updatedUserData.completedSpecialTasks.includes(task._id) ? 'completed' : task.status
        }));
        setSpecialTasks(updatedSpecialTasks);

        setToastMessage({ title: "Success", description: `Task completed successfully! You earned ${task.coinsReward} coins.` });
      } else {
        if (task.taskType === 'web') {
          setSpecialTasks(prevTasks =>
            prevTasks.map(t =>
              t._id === taskId ? { ...t, codeError: true } : t
            )
          );
          setToastMessage({ title: "Error", description: "Invalid code. Please try again." });
        } else {
          setToastMessage({ title: "Error", description: "Failed to claim task. Please try again." });
        }
      }
    } catch (error) {
      console.error('Error claiming special task:', error);
      setToastMessage({ title: "Error", description: error.response?.data?.message || "Failed to claim task. Please try again." });
    }
  };

  const handleOtherSocialPlatforms = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }
      window.open(task.socialUrl, '_blank');
      setSocialTasks(prevTasks =>
        prevTasks.map(t =>
          t._id === taskId ? { ...t, status: 'ready_to_claim' } : t
        )
      );
    } catch (error) {
      console.error('Error opening social link:', error);
      setToastMessage({ title: "Error", description: "Failed to open social link. Please try again." });
    }
  };

  const handleClaimOtherSocialTask = async (taskId) => {
    try {
      const task = socialTasks.find(t => t._id === taskId);
      if (!task) {
        throw new Error('Task not found');
      }
      const response = await axios.post(`/api/social-tasks/${taskId}/claim`);
      if (response.data.success) {
        setSocialTasks(prevTasks =>
          prevTasks.map(t =>
            t._id === taskId ? { ...t, status: 'completed' } : t
          )
        );
        setUserData(prevUserData => ({
          ...prevUserData,
          coins: response.data.updatedUser.coins,
          completedSocialTasks: response.data.updatedUser.completedSocialTasks
        }));
        setScore(response.data.updatedUser.coins);
        setToastMessage({ title: "Success", description: `Task completed successfully! You earned ${task.coinsReward} coins.` });
      } else {
        setToastMessage({ title: "Error", description: response.data.message || "Failed to claim task. Please try again." });
      }
    } catch (error) {
      console.error('Error claiming social task:', error);
      setToastMessage({ title: "Error", description: error.response?.data?.message || "Failed to claim task. Please try again." });
    }
  };

  const getOrderedBrandsFunc = useCallback((allBrands, count = 2, country, category, subCategory) => {
    if (!Array.isArray(allBrands) || allBrands.length === 0) {
      return [];
    }
    let filteredBrands = allBrands;
    if (country && country !== 'Global') {
      filteredBrands = filteredBrands.filter(brand =>
        brand.countries && Array.isArray(brand.countries) && brand.countries.some(c => c.code === country)
      );
    }
    if (category) {
      filteredBrands = filteredBrands.filter(brand => brand.category === category);
      if (subCategory) {
        filteredBrands = filteredBrands.filter(brand => brand.subCategory === subCategory);
      }
    }
    if (filteredBrands.length < count) {
      const otherBrands = allBrands.filter(brand => !filteredBrands.includes(brand));
      filteredBrands = [...filteredBrands, ...otherBrands];
    }
    const sortedBrands = filteredBrands.sort((a, b) => {
      if (country && country !== 'Global') {
        const aInCountry = a.countries && Array.isArray(a.countries) && a.countries.some(c => c.code === country);
        const bInCountry = b.countries && Array.isArray(b.countries) && b.countries.some(c => c.code === country);
        if (aInCountry && !bInCountry) return -1;
        if (!aInCountry && bInCountry) return 1;
      }
      return (b.frequency || 0) - (a.frequency || 0);
    });
    return sortedBrands.slice(0, count);
  }, []);

  // Функция для обновления данных пользователя на сервере
  const updateUserData = useCallback(async (updatedData) => {
    try {
      const response = await axios.put('/api/users/me', updatedData, {
        headers: {
          'X-Telegram-Init-Data': localStorage.getItem('telegramInitData')
        }
      });
      setUserData(response.data);
      setGlobalUser(response.data);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  }, [setGlobalUser]);

  // Эффект для загрузки начальных данных
  const fetchInitialData = async () => {
  setIsLoading(true);
  try {
    const [categoriesRes, ranksRes, tasksRes, faqsRes, countriesRes, referralsRes, brandsRes] = await Promise.all([
      axios.get('/api/categories'),
      axios.get('/api/ranks'),
      axios.get('/api/tasks'),
      axios.get('/api/faq'),
      axios.get('/api/countries'),
      axios.get('/api/referrals'),
      axios.get('/api/brands')
    ]);

    setCategories(categoriesRes.data);
    setRanks(ranksRes.data);
    setTasks(tasksRes.data);
    setFaqs(faqsRes.data);
    setCountries(countriesRes.data);
    setReferrals(referralsRes.data);
    if (Array.isArray(brandsRes.data)) {
      setBrands(brandsRes.data);
      const initialBrands = getOrderedBrands(brandsRes.data, 2);
      setSelectedBrands(initialBrands);
    } else {
      console.error('Brands data is not an array:', brandsRes.data);
      setBrands([]);
      setSelectedBrands([]);
    }
  } catch (error) {
    console.error('Error fetching initial data:', error);
  } finally {
    setIsLoading(false);
  }
};

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Эффект для обновления брендов при изменении страны или категории
  useEffect(() => {
    if (selectedMainCategory || selectedSubCategory) {
      const filteredBrands = getFilteredBrands();
      console.log('Filtered brands after category/subcategory change:', filteredBrands);
      if (filteredBrands.length > 0) {
        setSelectedBrands(filteredBrands);
      } else {
        setToastMessage({ title: "Info", description: "No brands in selected category/subcategory." });
      }
    }
  }, [selectedMainCategory, selectedSubCategory, getFilteredBrands, setToastMessage]);

  const toggleFaq = (id) => {
    setOpenFaqId(openFaqId === id ? null : id);
  };

  const renderCategoryButton = (category, isSubCategory = false) => (
    <Button
      key={category}
      onClick={() => handleCategorySelect(category, isSubCategory)}
      className={`justify-start w-full mb-2 rounded-[30px] ${(isSubCategory ? selectedSubCategory : selectedCategory) === category
        ? 'bg-[#e0ff89] text-black'
        : 'bg-[#1a1a1a] text-white hover:text-[#e0ff89]'
        } ${isSubCategory ? 'text-sm' : ''}`}
    >
      {category}
    </Button>
  );

  const handleCategorySelect = useCallback((categoryId) => {
    setSelectedMainCategory(prev => prev === categoryId ? null : categoryId);
    // Не закрываем диалог и не меняем selectedSubCategory здесь
  }, []);

  const handleSubCategorySelect = useCallback((subCategory) => {
    setSelectedSubCategory(subCategory);
    setIsCategoryDialogOpen(false); // Закрываем диалог только при выборе подкатегории

    // Применяем фильтрацию при выборе подкатегории
    const filteredBrands = getFilteredBrands().filter(brand =>
      brand.subCategory === subCategory
    );
    if (filteredBrands.length > 0) {
      setSelectedBrands(getTwoRandomBrands(filteredBrands));
    } else {
      setToastMessage({ title: "Info", description: "No brands in this subcategory." });
      const mainCategoryBrands = getFilteredBrands();
      setSelectedBrands(getTwoRandomBrands(mainCategoryBrands));
    }
  }, [getFilteredBrands, getTwoRandomBrands, setToastMessage]);

  useEffect(() => {
    if (selectedMainCategory) {
      setIsCategoryDialogOpen(true);
    }
  }, [selectedMainCategory]);

  const closeDialog = () => {
    setIsCategoryDialogOpen(false);
  };

  const toggleCategory = (categoryId) => {
    setSelectedMainCategory(prevCategory =>
      prevCategory === categoryId ? '' : categoryId
    );
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(prevMenu => {
      if (prevMenu === menu) {
        setTranslateY(0);
        return null;
      } else {
        setTranslateY(0);
        return menu;
      }
    });
  };

  const handleTouchStart = (e) => {
    if (e.target.closest('.drag-handle')) {
      setIsDragging(true);
      setTouchStart(e.targetTouches[0].clientY);
    }
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentTouch = e.targetTouches[0].clientY;
    const diff = currentTouch - touchStart;
    if (diff > 0) {
      setTranslateY(diff);
    }
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    if (translateY > 100) {
      handleMenuClick(null);
    }
    setTranslateY(0);
    setIsDragging(false);
  };


  const closeMenu = () => {
    setActiveMenu(null);
  };

  const vibrate = () => {
    if (navigator.vibrate) {
      navigator.vibrate(50);
    }
  };

  const handleWithVibration = (handler) => {
    return (...args) => {
      vibrate();
      handler(...args);
    };
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const canActivateRank = (rank) => {
    return score >= rank.requiredCoins &&
      battlesWon >= rank.battlesRequired &&
      level >= rank.level &&
      (!currentRank || rank.level === currentRank.level + 1) &&
      !rank.isActivated;
  };

  const availableCountries = useMemo(() => {
    const countryCodes = new Set(brands.flatMap(brand => brand.countries ? brand.countries.map(c => c.code) : []));
    return countries.filter(country => countryCodes.has(country.code) || country.code === 'Global');
  }, [brands, countries]);

  const handleCountryChange = useCallback((newCountry) => {
    console.log('Changing country to:', newCountry);
    setSelectedCountry(newCountry);

    // Обновляем бренды после изменения страны
    setTimeout(() => {
      const filteredBrands = getFilteredBrands();
      console.log('Filtered brands after country change:', filteredBrands);
      if (filteredBrands.length > 0) {
        const newBrands = getTwoRandomBrands(filteredBrands);
        setSelectedBrands(newBrands);
      } else {
        setToastMessage({ title: "Info", description: "No brands in this country." });
      }
      setIsCountryDialogOpen(false);
    }, 0);
  }, [getFilteredBrands, getTwoRandomBrands, setToastMessage]);

  const UpgradeAnimation = () => (
    <div className="fixed inset-0 z-50 flex items-center justify-center pointer-events-none">
      <div className="absolute inset-0 bg-black opacity-80"></div>
      <div className="relative">
        <p className="text-4xl font-bold text-white mb-4">Rank Upgraded!</p>
        <div className="space-y-2">
          {[...Array(100)].map((_, index) => (
            <div
              key={index}
              className="w-4 h-4 bg-yellow-400 rounded-full animate-confetti"
              style={{
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 2}s`,
                animationDuration: `${Math.random() * 3 + 2}s`
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );

  const updateUser = async (updatedData) => {
    try {
      const response = await axios.put(`/api/user/${userData._id}`, updatedData);
      setGlobalUser(response.data);
      setUserData(response.data);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  // Обработчик выбора бренда
  const handleBrandSelect = useCallback(async (brand, index) => {
    if (navigator.vibrate) {
      navigator.vibrate(50);
    }
    if (availableBattles > 0) {
      setSelectedBrandIndex(index);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found');
        }
        const response = await axios.post('/api/users/stats', {
          battlesWon: 1,
          coinsEarned: 1,
          brandId: brand._id
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        const updatedUser = response.data;

        setUserData(prevUserData => ({
          ...prevUserData,
          coins: updatedUser.coins,
          battles: updatedUser.battles,
          availableBattles: updatedUser.availableBattles,
          level: updatedUser.level
        }));
        setGlobalUser(prevGlobalUser => ({
          ...prevGlobalUser,
          coins: updatedUser.coins,
          battles: updatedUser.battles,
          availableBattles: updatedUser.availableBattles,
          level: updatedUser.level
        }));
        setAvailableBattles(updatedUser.availableBattles);
        setScore(updatedUser.coins);
        setBattlesWon(prevBattlesWon => prevBattlesWon + 1);

        setStats(prevStats => ({
          battlesWon: prevStats.battlesWon + 1,
          brandsFaced: [...prevStats.brandsFaced, brand]
        }));

        // Выбираем новую пару брендов
        const nextFilteredBrands = getFilteredBrands().filter(b => b._id !== brand._id);
        if (nextFilteredBrands.length >= 2) {
          const nextNewBrands = getTwoRandomBrands(nextFilteredBrands);
          setSelectedBrands(nextNewBrands);
        } else {
          console.warn('Not enough brands to select two new ones');
          setToastMessage({ title: "Warning", description: "Not enough brands available for the next battle." });
        }

        setSelectedBrandIndex(null);
      } catch (error) {
        console.error('Error updating user data:', error);
        setToastMessage({ title: "Error", description: error.message || "An error occurred. Please try again." });
        setSelectedBrandIndex(null);
      }
    } else {
      setToastMessage({ title: "No Battles Available", description: "Please wait for battles to regenerate." });
    }
  }, [availableBattles, getFilteredBrands, getTwoRandomBrands, setToastMessage]);

  useEffect(() => {
    console.log('remainingBattles updated:', remainingBattles);
  }, [remainingBattles]);

  useEffect(() => {
    const currentLevel = calculateLevel(battlesWon);
    const battlesForCurrentLevel = currentLevel === 1 ? 0 : 1000 + (currentLevel - 2) * 200;
    const battlesForNextLevel = currentLevel === 1 ? 1000 : 1000 + (currentLevel - 1) * 200;
    const battlesInCurrentLevel = battlesWon - battlesForCurrentLevel;
    const progress = Math.min(100, Math.max(0, (battlesInCurrentLevel / (battlesForNextLevel - battlesForCurrentLevel)) * 100));
    setLevelProgress(progress);
    setLevel(currentLevel);
  }, [battlesWon]);

  const calculateLevel = (battles) => {
    if (battles < 1000) return 1;
    return Math.floor((battles - 1000) / 200) + 2;
  };

  useEffect(() => {
    localStorage.setItem('remainingBattles', remainingBattles.toString());
  }, [remainingBattles]);

  useEffect(() => {
    const savedState = localStorage.getItem('userState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setScore(parsedState.score);
      setBattlesWon(parsedState.battlesWon);
      setRemainingBattles(parsedState.remainingBattles);
      setLevel(parsedState.level);
    }
  }, []);

  const handleRankUpgrade = async (rank) => {
    if (canActivateRank(rank)) {
      const newScore = score - rank.requiredCoins;
      const updatedRanks = ranks.map(r =>
        r.level === rank.level ? { ...r, isActivated: true } : r
      );

      await updateUserDataCallback({
        score: newScore,
        remainingBattles: rank.battleLimit,
        currentRank: rank._id
      });

      setScore(newScore);
      setRemainingBattles(rank.battleLimit);
      setCurrentRank(rank);
      setShowUpgradeAnimation(true);
      setTimeout(() => setShowUpgradeAnimation(false), 5000);
      setRanks(updatedRanks);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [categoriesResponse, ranksResponse, tasksResponse, faqsResponse, countriesResponse, referralsResponse] = await Promise.all([
          axios.get('/api/categories'),
          axios.get('/api/ranks'),
          axios.get('/api/tasks'),
          axios.get('/api/faq'),
          axios.get('/api/countries'),
          axios.get('/api/referrals')
        ]);

        console.log('Fetched referrals:', referralsResponse.data);
        const sortedCategories = categoriesResponse.data.sort((a, b) => a.order - b.order);
        setCategories(sortedCategories);
        setRanks(ranksResponse.data);
        setTasks(tasksResponse.data);
        setFaqs(faqsResponse.data);
        setCountries(countriesResponse.data);
        setReferrals(referralsResponse.data);

      } catch (error) {
        console.error('Ошибка получения данных:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log('Current referrals state:', referrals);
  }, [referrals]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingBattles(prev => {
        const newValue = Math.min(prev + 1, currentRank?.battleLimit || 50);
        return newValue;
      });
    }, 10 * 60 * 1000);

    return () => clearInterval(interval);
  }, [currentRank]);

  const BATTLE_RECOVERY_TIME = 10 * 60 * 1000; // 10 минут в миллисекундах

  const calculateAvailableBattles = (lastUpdate, currentBattles, battleLimit, now) => {
    const timePassed = now.getTime() - new Date(lastUpdate).getTime();
    const recoveredBattles = Math.floor(timePassed / (BATTLE_RECOVERY_TIME * 1000));
    return Math.min(currentBattles + recoveredBattles, battleLimit);
  };

  const getNextBattleTime = (lastUpdate, currentBattles, battleLimit) => {
    if (currentBattles >= battleLimit) return null;
    const timePassed = Date.now() - lastUpdate.getTime();
    const timeToNextBattle = BATTLE_RECOVERY_TIME * 1000 - (timePassed % (BATTLE_RECOVERY_TIME * 1000));
    return new Date(Date.now() + timeToNextBattle);
  };

  useEffect(() => {
    let timer;

    const updateBattles = () => {
      const now = new Date();
      setUserData(prevData => {
        const availableBattles = calculateAvailableBattles(
          prevData.lastBattleUpdate,
          prevData.currentBattles,
          prevData.totalBattles,
          now
        );

        if (availableBattles > prevData.currentBattles) {
          axios.post('/api/users/update-battles', { battles: availableBattles, lastUpdate: now.toISOString() })
            .catch(error => console.error('Error updating battles:', error));

          return {
            ...prevData,
            currentBattles: prevData.totalBattles - availableBattles,
            lastBattleUpdate: now
          };
        }

        return prevData;
      });

      setRemainingBattles(prevBattles => {
        const newBattles = calculateAvailableBattles(
          userData.lastBattleUpdate,
          userData.currentBattles,
          userData.totalBattles,
          now
        );
        return newBattles !== prevBattles ? newBattles : prevBattles;
      });

      const nextBattleTime = getNextBattleTime(now, userData.currentBattles, userData.totalBattles);

      if (nextBattleTime) {
        const delay = nextBattleTime.getTime() - now.getTime();
        timer = setTimeout(updateBattles, delay);
      }
    };

    const initializeBattles = () => {
      const now = new Date();
      const availableBattles = calculateAvailableBattles(
        userData.lastBattleUpdate,
        userData.currentBattles,
        userData.totalBattles,
        now
      );

      if (availableBattles !== userData.totalBattles - userData.currentBattles) {
        setUserData(prevData => ({
          ...prevData,
          currentBattles: prevData.totalBattles - availableBattles,
          lastBattleUpdate: now
        }));
        setRemainingBattles(availableBattles);
      }

      updateBattles();
    };

    if (userData.lastBattleUpdate && userData.currentBattles !== undefined && userData.totalBattles) {
      initializeBattles();
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [userData]);

  useEffect(() => {
    const savedRemainingBattles = localStorage.getItem('remainingBattles');
    if (savedRemainingBattles) {
      setRemainingBattles(parseInt(savedRemainingBattles, 10));
    }
  }, []);

  const getTopBrands = (limit = 5) => {
    const brandCounts = stats.brandsFaced.reduce((acc, brand) => {
      const brandName = brand.name;
      acc[brandName] = (acc[brandName] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(brandCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, limit)
      .map(([brand, count]) => ({ brand, count }));
  };

  const handleTaskCompletion = (task) => {
    if (task.type === 'referral') {
      setIsReferralDialogOpen(true);
    } else {
      setRemainingBattles(prev => prev + task.battleReward);
    }
  };

  const copyReferralLink = () => {
    console.log('copyReferralLink function called');
    console.log('Current referralLink:', referralLink);

    if (!referralLink) {
      console.log('Referral link is not available');
      setToastMessage({ title: "Error", description: "Referral link is not available" });
      return;
    }

    const textArea = document.createElement("textarea");
    textArea.value = referralLink;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      console.log('Referral link copied successfully');
      setToastMessage({ title: "Success", description: "Referral link copied!" });
    } catch (err) {
      console.error('Error during copy operation:', err);
      setToastMessage({ title: "Error", description: "Failed to copy link" });
    }
    document.body.removeChild(textArea);
  };

  const handleBrandInfo = (brand) => {
    setSelectedBrandInfo(brand);
  };

  const LeaderboardTable = ({ data, period, handlePeriodChange }) => {
    const handleTabChange = (value) => {
      if (navigator.vibrate) {
        navigator.vibrate(50);
      }
      handlePeriodChange(value);
    };

    return (
      <Tabs defaultValue={period} onValueChange={handleTabChange}>
        <div className="space-y-2">
          <TabsList className="w-full grid grid-cols-3 bg-black rounded-[30px] p-1">
            <TabsTrigger
              value="week"
              className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
            >
              Week
            </TabsTrigger>
            <TabsTrigger
              value="month"
              className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
            >
              Month
            </TabsTrigger>
            <TabsTrigger
              value="allTime"
              className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
            >
              All Time
            </TabsTrigger>
          </TabsList>
          <TabsContent value="week" className="mt-2">
            {renderLeaderboard(data)}
          </TabsContent>
          <TabsContent value="month" className="mt-2">
            {renderLeaderboard(data)}
          </TabsContent>
          <TabsContent value="allTime" className="mt-2">
            {renderLeaderboard(data)}
          </TabsContent>
        </div>
      </Tabs>
    );
  };

  const renderLeaderboard = (data) => (
    <div className="space-y-2">
      {data.map((user, index) => (
        <div key={user._id} className="flex items-center justify-between py-2 border-b border-black border-opacity-20">
          <div className="flex items-center">
            <span className="w-6 text-black font-bold mr-2">
              {index + 1}
            </span>
            <Avatar className="w-8 h-8 mr-2">
              <AvatarImage src={user.avatar} alt={user.name} />
              <AvatarFallback>{user.name[0]}</AvatarFallback>
            </Avatar>
            <span className="text-black">{user.name}</span>
          </div>
          <div className="flex items-center">
            <span className="text-black font-bold mr-1">{user.coins.toLocaleString()}</span>
            <img src="/images/general/coin.svg" alt="coin" className="w-4 h-4" />
          </div>
        </div>
      ))}
    </div>
  );

  const fetchLeaderboardData = async (period) => {
    try {
      const response = await axios.get(`/api/users/leaderboard/${period}`);
      setLeaderboardData(response.data);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
      setToastMessage({ title: "Error", description: "Failed to fetch leaderboard data" });
    }
  };

  const handlePeriodChange = (period) => {
    setLeaderboardPeriod(period);
    fetchLeaderboardData(period);
  };

  useEffect(() => {
    fetchLeaderboardData(leaderboardPeriod);
  }, []);

  const handleAddBrand = () => {
    // Логика добавления бренда
    console.log('Adding brand');
  };

  const handleAddTask = () => {
    // Логика добавления задачи
    console.log('Adding task');
  };

  const toggleProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden text-white relative">
      <AnimatedBackground />
      <div className="w-full relative p-4">
        <div className="flex flex-col justify-between p-4 bg-cover bg-center relative rounded-[30px] overflow-hidden" style={{
          backgroundImage: `url(${currentRank?.backgroundImage || '/default-background.jpg'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="relative z-10 flex justify-between items-start w-full mb-4">
            <div className="flex items-center space-x-4">
              <Avatar className="w-16 h-16 avatar-container">
                {userData.avatar ? (
                  <AvatarImage src={userData.avatar} alt="User Avatar" />
                ) : (
                  <AvatarFallback>{getInitials(userData.firstName || userData.username || 'User')}</AvatarFallback>
                )}
              </Avatar>
              <div className="flex-grow">
                <h1 className="text-2xl font-bold text-white">
                  {userData.firstName || userData.username || 'User'}
                </h1>
                <p className="text-sm text-gray-300 mt-1">{currentRank?.name || 'Kettle'}</p>
                <p className="text-xl font-bold text-white flex items-center mt-1">
                  {score !== undefined ? (
                    <>
                      {Math.floor(score)}
                      <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                    </>
                  ) : '0'}
                </p>
              </div>
            </div>
            <Button className="w-10 h-10 rounded-full p-0 overflow-hidden bg-transparent" onClick={toggleProfile}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
            </Button>
          </div>
          <div className="relative z-10 w-full bg-black rounded-full h-3 mt-2">
            <div className="absolute left-0 top-0 bottom-0 bg-[#e0ff89] rounded-full transition-all duration-300 ease-in-out" style={{ width: `${levelProgress}%` }}></div>
            <div className="absolute inset-0 flex items-center justify-center text-xs font-medium">
              <span className="text-white mix-blend-difference">Level {userData.level}: {Math.floor(levelProgress)}%</span>
            </div>
          </div>
        </div>
      </div>
      <div className={`fixed inset-0 bg-black z-[60] transition-transform duration-300 ease-in-out ${isProfileOpen ? 'translate-y-0' : 'translate-y-full'}`}>
        <div className="h-full overflow-y-auto scrollbar-hide">
          <div className="flex justify-between items-center p-4">
            <h2 className="text-xl font-bold text-white">My Account</h2>
            <Button onClick={toggleProfile} className="text-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </Button>
          </div>
          <UserProfile user={userData} onAddBrand={handleAddBrand} onAddTask={handleAddTask} onClose={toggleProfile} />
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-4">
        <CardContent className="space-y-4 pt-0">
          <Dialog open={isCountryDialogOpen} onOpenChange={setIsCountryDialogOpen}>
            <DialogContent className="bg-black rounded-[30px] p-6">
              <DialogHeader>
                <DialogTitle className="text-white">Select Country</DialogTitle>
              </DialogHeader>
              <div className="space-y-4">
                <Button
                  className={`w-full h-6 flex items-center justify-center space-x-2 rounded-[30px] transition-colors ${selectedCountry === 'Global'
                    ? 'bg-[#e0ff89] text-black'
                    : 'bg-zinc-800 text-white hover:bg-[#e0ff89] hover:text-black'
                    }`}
                  onClick={() => {
                    handleCountryChange('Global');
                    setIsCountryDialogOpen(false);
                  }}
                >
                  <span role="img" aria-label="Globe">🌐</span>
                  <span>Global</span>
                </Button>
                <div className="grid grid-cols-2 gap-4">
                  {countries.map((country) => (
                    <Button
                      key={country.code}
                      className={`flex items-center space-x-2 rounded-[30px] transition-colors h-6 ${selectedCountry === country.code
                        ? 'bg-[#e0ff89] text-black'
                        : 'bg-zinc-800 text-white hover:bg-[#e0ff89] hover:text-black'
                        }`}
                      onClick={() => {
                        handleCountryChange(country.code);
                        setIsCountryDialogOpen(false);
                      }}
                    >
                      <ReactCountryFlag countryCode={country.code} svg />
                      <span>{country.name}</span>
                    </Button>
                  ))}
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <div
            className={`fixed inset-0 z-50 ${isCategoryDialogOpen ? '' : 'pointer-events-none'}`}
          >
            <div
              className={`fixed bottom-0 left-0 right-0 bg-black rounded-t-3xl overflow-hidden flex flex-col`}
              style={{
                transform: isCategoryDialogOpen ? 'translateY(0)' : 'translateY(100%)',
                transition: 'transform 0.3s ease-in-out',
                height: 'calc(100% - 4rem)'
              }}
            >
              <div className="h-6 w-full bg-black cursor-grab active:cursor-grabbing drag-handle">
                <div className="h-1 w-16 bg-white rounded-full mx-auto mt-2"></div>
              </div>
              <div className="flex-1 overflow-y-auto px-4 py-2 scrollbar-hide">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-bold text-white">Categories</h2>
                  <button
                    onClick={() => setIsCategoryDialogOpen(false)}
                    className="text-white cursor-pointer focus:outline-none"
                  >
                    <X size={20} />
                  </button>
                </div>
                <div className="relative pb-24">
                  <div className="space-y-[-60px]">
                    {Object.entries(categories)
                      .sort(([, a], [, b]) => a.order - b.order)
                      .map(([categoryId, category], index) => {
                        const isExpanded = selectedMainCategory === categoryId;
                        const IconComponent = category.icon ? LucideIcons[category.icon] : LucideIcons.Folder;
                        return (
                          <div
                            key={categoryId}
                            className="relative transition-all duration-300 ease-in-out"
                            style={{
                              zIndex: index + 1, // Меняем на прямой порядок
                            }}
                          >
                            <div
                              className={`rounded-[30px] transition-all duration-300 ease-in-out cursor-pointer ${isExpanded ? '' : 'pb-12'
                                }`}
                              style={{
                                backgroundColor: category.color,
                                color: category.textColor,
                                boxShadow: '0 -7px 20px 0 rgba(0, 0, 0, 0.3)',
                              }}
                            >
                              <div
                                className="flex justify-between items-center p-6"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCategorySelect(categoryId);
                                }}
                              >
                                <div className="flex items-center space-x-3">
                                  <IconComponent size={48} />
                                  <div>
                                    <h3 className="font-bold text-2xl">{category.name}</h3>
                                    <p className="text-base">{category.subCategories.length} Subcategory</p>
                                  </div>
                                </div>
                                <ChevronRight
                                  size={20}
                                  className={`transition-transform duration-300 ${isExpanded ? 'rotate-90' : ''}`}
                                />
                              </div>
                              <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px]' : 'max-h-0'}`}>
                                <div className="space-y-2 p-4 pt-0 pb-20">
                                  {category.subCategories.map((subCategory, index) => (
                                    <div
                                      key={index}
                                      className="bg-white bg-opacity-50 rounded-[30px] pl-4 pb-2 pr-4 pt-2 text-base cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleSubCategorySelect(subCategory);
                                      }}
                                    >
                                      {subCategory}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-0">
            <h2 className="text-2xl font-bold text-white">Battle Brands</h2>
          </div>
          <div className="flex flex-col items-center mt-2">
            <div className="flex justify-center items-center w-full mb-4">
              {selectedBrands.slice(0, 2).map((brand, index) => (
                <React.Fragment key={brand._id}>
                  {index === 1 && <img src="/images/general/vs.svg" alt="VS" className="w-10 h-10 mx-4" />}
                  <div className="flex flex-col items-center relative" style={{ width: 'calc(50% - 2rem)' }}>
                    <div className="relative">
                      <BrandAvatar
                        brand={brand}
                        isSelected={selectedBrandIndex === index}
                        onClick={() => handleBrandSelect(brand, index)}
                        remainingBattles={remainingBattles}
                      >
                        <Avatar className="w-24 h-24">
                          <AvatarImage
                            src={brand.logo || `https://logo.clearbit.com/${brand.name.toLowerCase().replace(/\s+/g, '')}.com`}
                            alt={brand.name}
                          />
                          <AvatarFallback>{brand.name.charAt(0)}</AvatarFallback>
                        </Avatar>
                      </BrandAvatar>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleBrandInfo(brand);
                        }}
                        className={`absolute ${index === 0 ? 'left-0' : 'right-0'} bottom-0 bg-zinc-900 rounded-full w-8 h-8 flex items-center justify-center shadow-zinc-100`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      className="cursor-pointer h-12 flex items-start justify-center w-full mt-2"
                    >
                      <p className="text-center font-medium text-white leading-tight line-clamp-2">
                        {brand.name}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="w-full max-w-md">
              <div className="w-full bg-gray-400 rounded-full h-6 relative">
                <div
                  className="bg-white h-6 rounded-full"
                  style={{ width: `${(availableBattles / battleLimit) * 100}%` }}
                ></div>
                <div className="absolute inset-0 flex items-center justify-center text-xs text-black font-medium">
                  {availableBattles} / {battleLimit} Battles Available
                </div>
              </div>
              <div className="flex items-center mt-6">
                <Button
                  className="flex-grow bg-[#e0ff89] text-black rounded-[30px] px-4 py-2 mr-2"
                  onClick={() => setIsCategoryDialogOpen(true)}
                >
                  {selectedSubCategory || selectedCategory || 'Select Category'}
                </Button>
                <Button
                  className="w-10 h-10 bg-white text-black rounded-full w-9 h-9 flex items-center justify-center"
                  onClick={() => setIsCountryDialogOpen(true)}
                >
                  {selectedCountry === 'Global' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  ) : (
                    <ReactCountryFlag countryCode={selectedCountry} svg style={{ width: '1.5em', height: '1.5em' }} />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
        <Dialog open={isReferralDialogOpen} onOpenChange={setIsReferralDialogOpen}>
          <DialogContent className="bg-gray-900 text-white">
            <DialogHeader>
              <DialogTitle>Invite a Friend</DialogTitle>
              <DialogDescription className="text-gray-400">
                Share this link with your friends to invite them to Battle Brands!
              </DialogDescription>
            </DialogHeader>
            <div className="flex items-center space-x-2">
              <Input value={userData?.referralLink} readOnly className="bg-gray-800 text-white" />
              <Button onClick={copyReferralLink} className="flex items-center space-x-2 bg-gray-700 text-white hover:bg-gray-600">
                <Copy size={16} />
                <span>Copy</span>
              </Button>
            </div>
            <Button
              className="w-full mt-4 bg-white text-black hover:bg-gray-200"
              onClick={() => {
                // Логика для отправки в Telegram
              }}
            >
              <Share2 className="mr-2" size={16} />
              Share on Telegram
            </Button>
          </DialogContent>
        </Dialog>
        {showUpgradeAnimation && <UpgradeAnimation />}
      </div>
      <div
        className={`fixed inset-0 z-40 ${activeMenu ? '' : 'pointer-events-none'}`}
      >
        <div
          className={`fixed bottom-0 left-0 right-0 ${activeMenu === 'Game' ? 'bg-black top-0' : 'bg-[#e1ff8a] rounded-t-3xl'} overflow-hidden flex flex-col`}
          style={{
            transform: activeMenu ? (isDragging ? `translateY(${translateY}px)` : 'translateY(0)') : 'translateY(100%)',
            height: activeMenu === 'Game' ? '100%' : 'calc(100% - 4rem)',
            transition: isDragging ? 'none' : 'transform 0.3s ease-in-out'
          }}
        >
          <div
            className={`${activeMenu === 'Game' ? 'h-0' : 'h-6'} w-full ${activeMenu === 'Game' ? 'bg-black' : 'bg-[#e1ff8a]'} cursor-grab active:cursor-grabbing drag-handle`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {activeMenu !== 'Game' && <div className="h-1 w-16 bg-black rounded-full mx-auto mt-2"></div>}
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="flex justify-between items-center mb-2 px-2">
              <h2 className={`text-2xl font-bold ${activeMenu === 'Game' ? 'text-white' : 'text-black'}`}>{activeMenu}</h2>
              <button
                onClick={() => setActiveMenu(null)}
                className={`${activeMenu === 'Game' ? 'text-white' : 'text-black'} cursor-pointer focus:outline-none`}
              >
                <X size={20} />
              </button>
            </div>
            <div className={`flex-1 overflow-y-auto px-2 pb-24 ${activeMenu === 'Game' ? 'text-white' : 'text-black'} scrollbar-hide`}>
              {activeMenu === 'Rank' && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {ranks.map((rank, index) => {
                    const canUpgrade = canActivateRank(rank);
                    return (
                      <div key={rank.level} className="relative overflow-hidden rounded-[20px] bg-gray-800" style={{ boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)' }}>
                        <img src={rank.backgroundImage} alt={rank.name} className="absolute inset-0 w-full h-full object-cover" />
                        <div className="relative z-10 p-6 bg-black bg-opacity-50 h-full flex flex-col justify-between">
                          <div className="flex justify-between items-start">
                            <div>
                              <h3 className="text-2xl font-bold text-white">{rank.name}</h3>
                              <p className="text-sm text-gray-300">Level {rank.level}</p>
                            </div>
                            <div className="text-right">
                              <p className="text-lg font-semibold text-white">{rank.requiredCoins} coins</p>
                              <p className="text-sm text-gray-300">Limit: {rank.battleLimit}</p>
                            </div>
                          </div>
                          <div className="my-4">
                            <div className="w-full bg-gray-700 rounded-full h-4 relative">
                              <div
                                className="bg-white rounded-full h-4"
                                style={{ width: `${rank.isActivated ? 100 : Math.min((battlesWon / rank.battlesRequired) * 100, 100)}%` }}
                              ></div>
                              <div className="absolute inset-0 flex items-center justify-center text-xs text-black font-medium">
                                {rank.isActivated ? 'Completed' : `${battlesWon}/${rank.battlesRequired} battles`}
                              </div>
                            </div>
                          </div>
                          <div>
                            {!rank.isActivated ? (
                              <Button
                                onClick={() => handleRankUpgrade(rank)}
                                disabled={!canUpgrade}
                                className={`w-full rounded-[30px] ${canUpgrade
                                  ? 'bg-[#e0ff89] hover:bg-[#e0ff89] text-black shadow-lg'
                                  : 'bg-gray-700 text-gray-400 cursor-not-allowed'
                                  } font-bold py-2 px-4 transition-all duration-300`}
                              >
                                {canUpgrade ? 'Activate' : 'Locked'}
                              </Button>
                            ) : (
                              <div className="text-center text-black font-bold bg-[#e0ff89] bg-opacity-50 py-2 px-4 rounded-[30px]">Activated</div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {activeMenu === 'Tasks' && (
                <Tabs defaultValue="referrals" className="w-full">
                  <TabsList className="w-full grid grid-cols-4 bg-black rounded-[30px] p-1">
                    <TabsTrigger
                      value="referrals"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Referrals
                    </TabsTrigger>
                    <TabsTrigger
                      value="video"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Video
                    </TabsTrigger>
                    <TabsTrigger
                      value="social"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Social
                    </TabsTrigger>
                    <TabsTrigger
                      value="special"
                      onClick={handleWithVibration(() => { })}
                      className="text-white data-[state=active]:bg-[#e0ff89] data-[state=active]:text-black rounded-[30px] transition-colors hover:text-[#e0ff89]"
                    >
                      Special
                    </TabsTrigger>
                  </TabsList>
                  <div className="overflow-y-auto scrollbar-hide">
                    <TabsContent value="referrals" className="w-full">
                      <div className="flex justify-between mb-4">
                        <Button
                          className="flex-1 mr-2 bg-black text-white hover:bg-zinc-800 rounded-[30px] shadow-md px-4 py-2 text-sm font-medium"
                          onClick={() => {
                            console.log('Copy Link button clicked');
                            copyReferralLink();
                          }}
                        >
                          <Copy size={16} className="mr-2" />
                          <span>Copy Link</span>
                        </Button>
                        <Button
                          className="flex-1 ml-2 bg-black text-white hover:bg-zinc-800 rounded-[30px] shadow-md px-4 py-2 text-sm font-medium"
                          onClick={() => {
                            if (window.Telegram && window.Telegram.WebApp) {
                              window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(userData.referralLink)}&text=${encodeURIComponent('Join Battle Brands with my referral link!')}`);
                            } else {
                              setToastMessage({ title: "Error", description: "Telegram Web App is not available" });
                            }
                          }}
                        >
                          <Users size={16} className="mr-2" />
                          <span>Share</span>
                        </Button>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {referrals.map((referral) => (
                          <div key={referral._id} className="relative overflow-hidden rounded-[20px] bg-gray-800" style={{ boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)' }}>
                            <img
                              src={referral.backgroundImage}
                              alt={referral.name}
                              className="absolute inset-0 w-full h-full object-cover"
                              onError={(e) => {
                                console.error('Error loading image:', e);
                                e.target.src = '/default-background.jpg';
                              }}
                            />
                            <div className="relative z-10 p-6 bg-black bg-opacity-50 h-full flex flex-col justify-between">
                              <div className="flex justify-between items-start">
                                <h3 className="text-2xl font-bold text-[#d3d3d3]">{referral.name}</h3>
                                <p className="text-2xl font-bold text-white flex items-center">
                                  {referral.coinsReward}
                                  <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                                </p>
                              </div>
                              <p className="text-sm text-[#a0a0a0] mt-1">{referral.description}</p>
                              <div className="mt-4 space-y-2">
                                <div className="text-right">
                                  {referral.battleLimitIncrease > 0 && (
                                    <p className="text-sm text-[#d3d3d3]">+{referral.battleLimitIncrease} Limit</p>
                                  )}
                                  {referral.battleRecoveryReduction > 0 && (
                                    <p className="text-sm text-[#d3d3d3]">-{Math.floor(referral.battleRecoveryReduction / 60)}m {referral.battleRecoveryReduction % 60}s Recovery</p>
                                  )}
                                </div>
                                <div className="w-full bg-zinc-400 rounded-full h-4 relative">
                                  <div
                                    className="bg-white h-4 rounded-full"
                                    style={{ width: `${Math.min((userData.referrals.length || 0) / referral.requiredReferrals, 1) * 100}%` }}
                                  ></div>
                                  <div className="absolute inset-0 flex items-center justify-center text-xs text-black font-medium">
                                    {userData.referrals.length || 0} / {referral.requiredReferrals} referrals
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabsContent>
                    <TabsContent value="video" className="w-full">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {videoTasks.map((task) => (
                          <div key={task._id} className="relative overflow-hidden rounded-[20px] bg-gray-800" style={{ boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)' }}>
                            <img
                              src={task.backgroundImage}
                              alt={task.name}
                              className="absolute inset-0 w-full h-full object-cover"
                              onError={(e) => {
                                console.error('Error loading image:', e);
                                e.target.src = '/default-background.jpg';
                              }}
                            />
                            <div className="relative z-10 p-6 bg-black bg-opacity-50 h-full flex flex-col justify-between">
                              <div className="flex justify-between items-start">
                                <div>
                                  <h3 className="text-2xl font-bold text-[#d3d3d3]">{task.name}</h3>
                                  <p className="text-sm text-[#a0a0a0] mt-1">{task.description}</p>
                                </div>
                                <div className="flex flex-col items-end">
                                  <p className="text-2xl font-bold text-white flex items-center">
                                    {task.coinsReward}
                                    <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                                  </p>
                                  <div className="mt-1">
                                    {socialIcons.YouTube && <socialIcons.YouTube className="w-6 h-6 text-white" />}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4 space-y-2">
                                {task.status && !['not_started', 'in_progress', 'ready_to_claim', 'completed'].includes(task.status) && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                    onClick={() => handleWatchVideo(task._id, task.videoUrl)}
                                  >
                                    Watch Video
                                  </Button>
                                )}
                                {task.status === 'in_progress' && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                    disabled
                                  >
                                    <div className="flex items-center justify-center">
                                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-black mr-2"></div>
                                      Watching...
                                    </div>
                                  </Button>
                                )}
                                {task.status === 'ready_to_claim' && (
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="text"
                                      placeholder="Enter Code"
                                      className={`flex-grow bg-black text-white placeholder-gray-500 rounded-[30px] py-2 px-4 h-10 ${task.codeError ? 'border-2 border-red-500' : ''}`}
                                      onChange={(e) => handleCodeInput(task._id, e.target.value)}
                                      value={taskCodes[task._id] || ''}
                                    />
                                    <Button
                                      className="bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 h-10"
                                      onClick={() => handleClaimVideoTask(task._id)}
                                    >
                                      Claim
                                    </Button>
                                  </div>
                                )}
                                {task.status === 'completed' && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                    disabled
                                  >
                                    Completed
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabsContent>
                    <TabsContent value="social" className="w-full">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {socialTasks.map((task) => (
                          <div key={task._id} className="relative overflow-hidden rounded-[20px] bg-gray-800" style={{ boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)' }}>
                            <img
                              src={task.backgroundImage}
                              alt={task.name}
                              className="absolute inset-0 w-full h-full object-cover"
                              onError={(e) => {
                                console.error('Error loading image:', e);
                                e.target.src = '/default-background.jpg';
                              }}
                            />
                            <div className="relative z-10 p-6 bg-black bg-opacity-50 h-full flex flex-col justify-between">
                              <div className="flex justify-between items-start">
                                <div>
                                  <h3 className="text-2xl font-bold text-[#d3d3d3]">{task.name}</h3>
                                  <p className="text-sm text-[#a0a0a0] mt-1">{task.description}</p>
                                </div>
                                <div className="flex flex-col items-end">
                                  <p className="text-2xl font-bold text-white flex items-center">
                                    {task.coinsReward}
                                    <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                                  </p>
                                  <div className="mt-1">
                                    {getSocialIcon(task.socialPlatform)}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4">
                                {task.status && !['not_started', 'subscribed', 'completed'].includes(task.status) && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                    onClick={() => handleSubscribe(task._id)}
                                  >
                                    Subscribe to {task.socialPlatform}
                                  </Button>
                                )}
                                {task.status === 'subscribed' && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                    onClick={() => handleClaimSocialTask(task._id)}
                                  >
                                    Claim
                                  </Button>
                                )}
                                {task.status === 'completed' && (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                    disabled
                                  >
                                    Completed
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabsContent>
                    <TabsContent value="special" className="w-full">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {specialTasks.map((task) => (
                          <div key={task._id} className="relative overflow-hidden rounded-[20px] bg-gray-800" style={{ boxShadow: '0 10px 10px rgba(0, 0, 0, 0.5)' }}>
                            <img
                              src={task.backgroundImage}
                              alt={task.name}
                              className="absolute inset-0 w-full h-full object-cover"
                              onError={(e) => {
                                console.error('Error loading image:', e);
                                e.target.src = '/default-background.jpg';
                              }}
                            />
                            <div className="relative z-10 p-6 bg-black bg-opacity-50 h-full flex flex-col justify-between">
                              <div className="flex justify-between items-start">
                                <div>
                                  <h3 className="text-2xl font-bold text-[#d3d3d3]">{task.name}</h3>
                                  <p className="text-sm text-[#a0a0a0] mt-1">{task.description}</p>
                                </div>
                                <div className="flex flex-col items-end">
                                  <p className="text-2xl font-bold text-white flex items-center">
                                    {task.coinsReward}
                                    <img src="/images/general/coin.svg" alt="coin" className="w-6 h-6 ml-1" />
                                  </p>
                                  <div className="mt-1">
                                    {task.taskType === 'app' ? <Smartphone className="w-6 h-6 text-white" /> : <Globe className="w-6 h-6 text-white" />}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4">
                                {task.status === 'completed' ? (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black rounded-[30px] py-2 px-4"
                                    disabled
                                  >
                                    Completed
                                  </Button>
                                ) : task.status === 'in_progress' && task.taskType === 'web' ? (
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="text"
                                      placeholder="Enter Code"
                                      className={`flex-grow bg-black text-white placeholder-gray-500 rounded-[30px] py-2 px-4 h-10 ${task.codeError ? 'border-2 border-red-500' : ''}`}
                                      onChange={(e) => handleCodeInput(task._id, e.target.value)}
                                      value={taskCodes[task._id] || ''}
                                    />
                                    <Button
                                      className="bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4 h-10"
                                      onClick={() => handleClaimSpecialTask(task._id)}
                                    >
                                      Claim
                                    </Button>
                                  </div>
                                ) : task.status === 'in_progress' && task.taskType === 'app' ? (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                    onClick={() => handleClaimSpecialTask(task._id)}
                                  >
                                    Claim
                                  </Button>
                                ) : (
                                  <Button
                                    className="w-full bg-[#e0ff89] text-black hover:bg-[#d1ef7a] rounded-[30px] py-2 px-4"
                                    onClick={() => handleSpecialTask(task._id)}
                                  >
                                    {task.taskType === 'app' ? 'Open App' : 'Go to Website'}
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabsContent>
                  </div>
                </Tabs>
              )}
              {activeMenu === 'Account' && (
                <div className="space-y-4 text-black">
                  <p className="font-medium">Your Statistics:</p>
                  <ul className="text-sm space-y-1">
                    <li>Battles won: {stats.battlesWon}</li>
                    <li>Coins earned: {score}</li>
                    <li>Referrals invited: {userData?.referralCount || 0}</li>
                  </ul>
                  <div className="h-60">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={getTopBrands(5)}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#888" />
                        <XAxis dataKey="brand" stroke="#000" />
                        <YAxis stroke="#000" />
                        <Tooltip
                          contentStyle={{ backgroundColor: '#f0f0f0', border: 'none' }}
                          labelStyle={{ color: '#000' }}
                          itemStyle={{ color: '#000' }}
                        />
                        <Legend wrapperStyle={{ color: '#000' }} />
                        <Bar dataKey="count" fill="#000000" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}
              {activeMenu === 'Game' && (
                <div className="fixed inset-0 z-50 bg-black">
                  <div className="h-full flex flex-col">
                    <div className="flex justify-between items-center p-4">
                      <h2 className="text-2xl font-bold text-white">Brand Growth</h2>
                      <button onClick={() => setActiveMenu(null)} className="text-white">
                        <X size={24} />
                      </button>
                    </div>
                    <div className="flex-grow px-4 pb-4">
                      <BrandGrowthGame
                        user={userData}
                        setUser={setUserData}
                        setGlobalUser={setGlobalUser}
                        onClose={() => setActiveMenu(null)}
                        updateUserData={updateUserDataCallback}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeMenu === 'Top' && (
                <div className="bg-[#e0ff89] rounded-[30px]">
                  <LeaderboardTable
                    data={leaderboardData}
                    period={leaderboardPeriod}
                    handlePeriodChange={handlePeriodChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`fixed bottom-4 left-4 right-4 ${activeMenu === 'Game' ? 'z-40' : 'z-50'}`}>
        <div className="max-w-screen-xl mx-auto main-menu rounded-[20px] p-1 flex items-center justify-between relative overflow-hidden">
          {[
            { icon: Home, label: 'Ranks', menu: 'Rank' },
            { icon: Gift, label: 'Tasks', menu: 'Tasks' },
            { icon: BarChart2, label: 'Game', menu: 'Game' },
            { icon: ShoppingCart, label: 'Shop', menu: 'Shop' },
            { icon: Star, label: 'Top', menu: 'Top' }
          ].map(({ icon: Icon, label, menu }) => (
            <div key={menu} className="relative flex-1">
              <Button
                variant="ghost"
                onClick={() => handleMenuClick(activeMenu === menu ? null : menu)}
                className={`
            main-menu-item w-full flex flex-col items-center justify-center py-2 px-4 transition-all
            ${activeMenu === menu ? 'active' : ''}
          `}
              >
                <Icon size={24} className="menu-icon" />
                <span className="text-xs menu-text">{label}</span>
              </Button>
            </div>
          ))}
        </div>
      </div>
      {toastMessage && (
        <Toast
          message={toastMessage.description}
          onClose={() => setToastMessage(null)}
        />
      )}
      <Dialog
        open={!!selectedBrandInfo}
        onOpenChange={() => setSelectedBrandInfo(null)}
      >
        <DialogContent className="bg-black rounded-[30px] p-6">
          <DialogHeader>
            <DialogTitle className="text-white flex items-center">
              <Avatar className="w-12 h-12 mr-4">
                <AvatarImage src={selectedBrandInfo?.logo} alt={selectedBrandInfo?.name} />
                <AvatarFallback>{selectedBrandInfo?.name[0]}</AvatarFallback>
              </Avatar>
              <h2 className="text-2xl font-bold">{selectedBrandInfo?.name}</h2>
            </DialogTitle>
          </DialogHeader>
          <div className="text-white mt-2 space-y-4">
            <div className="flex items-center">
              <span className="font-semibold mr-2">Country:</span>
              {selectedBrandInfo?.countries && selectedBrandInfo.countries.length > 0 && (
                <span>
                  {selectedBrandInfo.countries.map((country, index) => (
                    <React.Fragment key={country.code}>
                      <ReactCountryFlag countryCode={country.code} svg className="mr-1" />
                      <span>{country.name}</span>
                      {index < selectedBrandInfo.countries.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                </span>
              )}
            </div>
            <div>
              <span className="font-semibold">Category: </span>
              {selectedBrandInfo?.category && (
                <span>
                  {selectedBrandInfo.category.name}
                  {selectedBrandInfo.subCategory && ` ${selectedBrandInfo.subCategory}`}
                </span>
              )}
            </div>
            <div>
              <span className="font-semibold">Description: </span>
              <p className="text-gray-300">{selectedBrandInfo?.description}</p>
            </div>
            {selectedBrandInfo?.website && (
              <div>
                <span className="font-semibold">Website: </span>
                <a href={selectedBrandInfo.website} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 transition-colors">
                  {selectedBrandInfo.website}
                </a>
              </div>
            )}
            {selectedBrandInfo?.socialMedia && selectedBrandInfo.socialMedia.length > 0 && (
              <div>
                <span className="font-semibold">Social: </span>
                <div className="flex space-x-4 mt-2">
                  {selectedBrandInfo.socialMedia.map(social => {
                    const Icon = socialIcons[social.platform];
                    return Icon ? (
                      <a
                        key={social.platform}
                        href={social.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-400 hover:text-white transition-colors"
                      >
                        <Icon size={24} />
                      </a>
                    ) : null;
                  })}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BrandBattle;