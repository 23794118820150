import axios from 'axios';

const telegramStarsService = {
  initializePayment: async (brandData) => {
    try {
      const response = await axios.post('/api/payments/telegram-stars/initialize', brandData);
      if (response.data && response.data.paymentUrl) {
        return response.data;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error in initializePayment:', error);
      throw error;
    }
  },
  
  checkPaymentStatus: async (invoiceId) => {
    try {
      const response = await axios.get(`/api/payments/telegram-stars/status/${invoiceId}`);
      return response.data;
    } catch (error) {
      console.error('Error in checkPaymentStatus:', error);
      throw error;
    }
  }
};

export default telegramStarsService;