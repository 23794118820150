import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './layout/Sidebar';
import Dashboard from './Dashboard';
import BrandsManagement from './BrandsManagement';
import CategoriesManagement from './CategoriesManagement';
import CountriesManagement from './CountriesManagement';
import TasksManagement from './TasksManagement';
import RankManagement from './RankManagement';
import FAQManagement from './FAQManagement';
import UsersManagement from './UsersManagement';
import AdminsManagement from './AdminsManagement';
import BrandUsersManagement from './BrandUsersManagement';
import Settings from './Settings';
import AdminProfile from './AdminProfile';
import ReferralsManagement from './ReferralsManagement';
import VideoTasksManagement from './VideoTasksManagement';
import SocialTasksManagement from './SocialTasksManagement';
import SpecialTasksManagement from './SpecialTasksManagement';

const AdminPanel = () => {
  return (
    <div className="min-h-screen p-[50px] flex bg-cover bg-center" style={{backgroundImage: "url('/images/general/admin-background.webp')"}}>
      <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-md"></div>
      <Sidebar />
      <div className="flex-1 ml-[350px] overflow-hidden relative z-10">
        <div className="bg-black bg-opacity-60 backdrop-filter backdrop-blur-lg rounded-2xl p-6 h-full overflow-auto">
          <div className="min-w-[760px] text-white">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/brands" element={<BrandsManagement />} />
              <Route path="/categories" element={<CategoriesManagement />} />
              <Route path="/countries" element={<CountriesManagement />} />
              <Route path="/tasks" element={<TasksManagement />} />
              <Route path="/tasks/:category" element={<TasksManagement />} />
              <Route path="/tasks/referrals" element={<ReferralsManagement />} />
              <Route path="/tasks/video" element={<VideoTasksManagement />} />
              <Route path="/tasks/social" element={<SocialTasksManagement />} />
              <Route path="/tasks/special" element={<SpecialTasksManagement />} />
              <Route path="/ranks" element={<RankManagement />} />
              <Route path="/faq" element={<FAQManagement />} />
              <Route path="/accounts/users" element={<UsersManagement />} />
              <Route path="/accounts/admins" element={<AdminsManagement />} />
              <Route path="/accounts/brand-users" element={<BrandUsersManagement />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/profile" element={<AdminProfile />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;